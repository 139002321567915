

import React from 'react';
import {Col, Row} from "shards-react";
import {toFriendlyDate, toThousandCurrencyFormat} from "../../utils/helpers";

const IssueTransactionDetails = ({transaction}) => {
  if (transaction === null)
    return "";

  return (
    <React.Fragment>
      <Row>
        <Col sm="12">
          <p className="py-3 uppercase font-semibold">Transaction Details</p>
        </Col>
        <Col sm="4">Transaction Type</Col>
        <Col sm="8" className="text-right py-1">
          {transaction.transactionTypeDescription}
        </Col>

        <Col sm="4">Date</Col>
        <Col sm="8" className="text-right py-1">
          {toFriendlyDate(transaction.initiatedOn)}
        </Col>

        <Col sm="4">Status</Col>
        <Col sm="8" className="text-right py-1">
          {transaction.transactionStatusDescription}
        </Col>

        <Col sm="4">Sender</Col>
        <Col sm="8" className="text-right py-1">
          {transaction.senderNetwork} / {transaction.senderPhoneNumber}
        </Col>

        <Col sm="4">Receipient</Col>
        <Col sm="8" className="text-right py-1">
          {transaction.recipientPhoneNumber !== null ? transaction.recipientNetwork + " / " + transaction.recipientPhoneNumber : ""}
          {transaction.recipientAccountNumber !== null ? transaction.recipientBank + " / " + transaction.recipientAccountNumber : ""}
        </Col>

        <Col sm="4">Debited</Col>
        <Col sm="8" className="text-right py-1">
          {toThousandCurrencyFormat(transaction.amount)}
        </Col>

        <Col sm="4">Fee</Col>
        <Col sm="8" className="text-right py-1">
          {toThousandCurrencyFormat(transaction.fee)}
        </Col>

        <Col sm="4">Transferred</Col>
        <Col sm="8" className="text-right py-1">
          {toThousandCurrencyFormat(transaction.netAmount)}
        </Col>

        <Col sm="4">Reference</Col>
        <Col sm="8" className="text-right py-1">
          {transaction.transactionReference}
        </Col>

      </Row>
    </React.Fragment>
  )
}

export default IssueTransactionDetails;
