import React, {useRef, useState} from "react";

import {
  sendMessage
} from "../redux/messages";
import {useSelector, useDispatch} from "react-redux";
import {viewStates} from "../utils/constants";
import {
  Button, Card, CardBody, Col, Container,
  Form,
  FormInput,
  FormSelect,
  FormTextarea, Row
} from "shards-react";
import CustomFileUpload
  from "../components/components-overview/CustomFileUpload";
import ConfirmationDialog from "../components/custom/ConfirmationDialog";
import PageTitle from "../components/common/PageTitle";
import {createMuiTheme, MuiThemeProvider, Switch} from "@material-ui/core";
import {green} from "@material-ui/core/colors";

const Messages = () => {
  const state = useSelector(state => state.messages);
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [requestFeedback, setRequestFeedback] = useState(false);
  const [customerIds, setCustomerIds] = useState();
  const [networks, setNetworks] = useState();
  const dialogRef = useRef();
  let fileSelectDisabled = false;

  const renderResult = () => {
    if (state.viewState === viewStates.ERROR) {
      return (
        <div className="alert alert-danger alert-dismissible fade show mb-0"
             role="alert">
          <button type="button" className="close" data-dismiss="alert"
                  aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <i className="material-icons">error</i> {state.sendResult}
        </div>
      );
    } else if (state.viewState === viewStates.RETRIEVED && state.sendResult != null) {
      return (
        <div className="alert alert-success alert-dismissible fade show mb-0"
             role="alert">
          <button type="button" className="close" data-dismiss="alert"
                  aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <i className="material-icons">thumb_up</i> {state.sendResult}
        </div>
      );
    }
  };

  const isNumber = (str) => {
    return /^[1-9]\d*$/.test(str);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('Title', title);
    formData.append('Body', body);
    formData.append('RequestFeedback', requestFeedback);
    if (image != null) {
      formData.append('Image', image);
    }
    let customerIdCount = 0;
    if (customerIds != null) {
      customerIds.toString().split(/\s*(?:[,;\r\n\t]+|$)\s*/).forEach(val => {
        val = val.trim();
        if (isNumber(val)) {
          customerIdCount++;
          formData.append('CustomerIds', val);
        }
      });
    }

    if (networks != null && networks.toString().length > 0) {
      networks.toString().split(',').forEach(val => {
        val = val.trim();
        if (val != null && val.length > 0) {
          formData.append('Networks', val);
        }
      });
    }

    let networkStr = networks != null ? networks.toString() : "";
    let confirmMessage = customerIdCount > 0 ? "Send to " + customerIdCount + " user" + (customerIdCount > 1 ? "s" : "") : "Broadcast to ALL " + networkStr + " users";

    fileSelectDisabled = true;
    dialogRef.current.open(
      confirmMessage,
      "Are you sure you want to send?",
    ).then(doIt => {
      if (doIt) {
        dispatch(sendMessage(formData))
        fileSelectDisabled = false;
      }
    });
  }

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: green[500],
      },
    },
  });

  const RenderForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="w-full items-end">
          <div className="form-group">
            <FormInput
              className="form-control-lg mb-3"
              placeholder="Message title"
              required
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div>
            <FormTextarea
              className="form-control-lg mb-3"
              placeholder="Message body"
              required
              rows="8"
              onChange={e => setBody(e.target.value)}
            />
          </div>
          <div>
            <CustomFileUpload
              className="form-control-lg mb-3"
              label={image != null ? "Selected " + image.name : "Select an image (optional)"}
              onChange={e => setImage(e.target.files[0])}
              disabled={fileSelectDisabled}
            />
          </div>
          <div>
            <FormSelect
              className="form-control-lg mb-3"
              onChange={e => setNetworks(e.target.value)}
            >
              <option value="">All Networks</option>
              <option value="MTN">MTN</option>
              <option value="GLO">GLO</option>
              <option value="9MOBILE">9MOBILE</option>
              <option value="AIRTEL">AIRTEL</option>
            </FormSelect>
          </div>
          <div className="mb-2">
            <MuiThemeProvider theme={theme}>
              <Switch onChange={e => setRequestFeedback(e.target.checked)} color="secondary"/>
            </MuiThemeProvider>
            Request App Rating
          </div>
          <div>
            <FormTextarea
              className="form-control-lg mb-3"
              rows="3"
              placeholder="List of comma or line separated Customer Ids, eg. 12345, 67890, 76543"
              onChange={e => setCustomerIds(e.target.value)}
            />
          </div>
          <Button
            className="bg-blue-primary px-4 py-2 text-white border-none form-control-lg float-right">
            Send
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <Container fluid className="main-content-container px-4">
      {renderResult()}
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Send Broadcast Message" subtitle="Customer"
                   className="text-sm-left"/>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-4">
              {RenderForm()}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmationDialog ref={dialogRef}/>
    </Container>
  );
}

export default Messages;
