import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import Pagination from "../components/components-overview/custom/Pagination"
import {
  getOtps,
  setCurrentPage,
  setPhoneNumber,
  setGateway,
  setNetwork,
  setVerificationType
} from "../redux/otps";
import {useSelector, useDispatch} from "react-redux";
import {viewStates} from "../utils/constants";
import {spaceBeforeCap, toFriendlyDate} from "../utils/helpers";
import {Button, Col, Container, FormInput, FormSelect, Row} from "shards-react";
import "react-dates/initialize";
import SummaryCard from '../components/custom/SummaryCard';
import PageTitle from "../components/common/PageTitle";
import {tableHeaders} from "./gateways/GatewayTableData";
import ResponsiveTable from "../components/custom/ResponsiveTable";
import FilterCard from "../components/custom/FilterCard";
import OtpOverview from "./OtpOverview";

const Otps = () => {
  const state = useSelector(state => state.otps);
  const dispatch = useDispatch();
  const history = useHistory();
  let page = state.filter.currentPage;

  function handleRouteChange(pageNumber) {
    history.push(`/otps?page=${pageNumber}`);
    dispatch(setCurrentPage(pageNumber));
  };

  const [focusedInput1, setFocusedInput1] = useState(false);
  const [focusedInput2, setFocusedInput2] = useState(false);

  useEffect(() => {
    // dispatch(getMessageSummary());
    dispatch(getOtps());
  }, [page]);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <OtpsListView/>;
    }
  };

  const statusStyle = status => {
    if (status === "Verified") {
      return {
        color: "#1ac086",
        backgroundColor: "rgba(26,192,134,0.1)"
      };
    }
    if (status === "Expired") {
      return {
        color: "#ca4945",
        backgroundColor: "rgba(202,73,69,0.14)"
      };
    }
    return {
      color: "#e77816",
      backgroundColor: "rgba(202,116,69,0.14)"
    };
  };

  const Filter = () => {
    return (
      <div className="w-full flex items-end">
        {/* {JSON.stringify(state.filter.profileStatus)} */}
        <FormInput
          className="w-1/4 mx-2"
          placeholder="Search by phone"
          value={state.filter.phoneNumber}
          onChange={e => dispatch(setPhoneNumber(e.target.value))}
        />
        <FormSelect
          className="w-1/6 mx-2"
          onChange={e => dispatch(setVerificationType(e.target.value))}
        >
          <option>Filter by type..</option>
          <option value="">All</option>
          <option value="1">Registration</option>
          <option value="2">SIM</option>
          <option value="3">Password Reset</option>
        </FormSelect>
        <FormSelect
          className="w-1/6 mx-2"
          onChange={e => dispatch(setNetwork(e.target.value))}
        >
          <option>Filter by network..</option>
          <option value="">All</option>
          <option value="GLO">Glo</option>
          <option value="9MOBILE">9Mobile</option>
          <option value="MTN">MTN</option>
          <option value="AIRTEL">Airtel</option>
        </FormSelect>
        <Button
          // className="bg-blue-primary px-4 py-2 text-white border-none"
          className="px-4 mx-2"
          onClick={() => {
            dispatch(setCurrentPage(1));
            handleRouteChange(1);
            dispatch(getOtps())
          }}
        >
          Filter
        </Button>
      </div>
    );
  };

  const OtpsListView = () => {
    const otps = state.otps;
    const rowNumber = index => {
      return (
        state.filter.pageSize * state.filter.currentPage -
        state.filter.pageSize +
        (index + 1)
      );
    };

    return (
      <React.Fragment>
        <ResponsiveTable
          headerData={["", "Phone Number", "Code", "Status", "Network", "Gateway", "Date"]}
        >
          {otps.map((otp, index) => {
            return (
              <tr
                key={otp.id}
              >
                <td>
                  {rowNumber(index)}
                </td>
                <td>
                  {otp.phoneNumber}
                </td>
                <td>
                  {otp.code}
                </td>
                <td>
                    <span
                      className="px-2 py-1 uppercase text-center rounded-full inline-block font-normal text-sm"
                      style={statusStyle(otp.status)}>
                      {otp.status}
                    </span>
                </td>
                <td>
                  {otp.network}
                </td>
                <td>
                  {otp.gatewayName}
                </td>
                <td>
                  {toFriendlyDate(otp.generatedOn)}
                </td>
              </tr>
            );
          })}
        </ResponsiveTable>
        <Pagination
          pageSize={parseInt(state.filter.pageSize)}
          currentPage={parseInt(state.filter.currentPage)}
          totalRecords={state.filter.totalRecords}
          onRouteChange={handleRouteChange}
        />
      </React.Fragment>
    );
  };

  const Summary = () => {
    return (
      <div className="w-full flex items-center">
        {state.summary.map((summary, index) => {
          return (
            <SummaryCard
              title={summary.key}
              count={summary.count}
              key={index}
            />
          );
        })}
        {/* <SummaryCard title="total" value={getTotal()} /> */}
      </div>
    );
  };


  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="OTP Verifications" subtitle="Customer"
                   className="text-sm-left"/>
      </Row>
      <Row>
        <Col>
          {FilterCard(Filter())}
          {renderView()}
        </Col>
      </Row>
    </Container>
  );
};

export default Otps;
