import React, { useState, Fragment } from "react";
// import Router from "next/router";
// import {FaChevronLeft, FaChevronRight} from "react-icons/fa"
import { useHistory } from "react-router-dom";


const Pagination = props => {
  const [currentPageNumber, setCurrentPageNumber] = useState(
    parseInt(props.currentPage)
  );
  const pageSize = props.pageSize || 10;
  const totalRecords = props.totalRecords || 0;
  const { route } = props;

  const goToNextPage = () => {
    if (currentPageNumber < totalRecords) {
      let page = currentPageNumber
      setCurrentPageNumber(++page);
      props.onRouteChange(page)
    }
  };

  const goTo = () => {
    if (currentPageNumber >= 1 && currentPageNumber <= totalPages()) {
      if (route) {
        const history = useHistory();
        history.push(`${route}?page=${currentPageNumber}`)
        // Router.push({ pathname: route, query: { page: currentPageNumber } });
      }
    }
  };

  const goToPreviousPage = () => {
    if (currentPageNumber > 1) {
      let page = currentPageNumber
      setCurrentPageNumber(--page);
      props.onRouteChange(page)
    }
  };

  const endCount = () => {
    let upperCount = pageSize * currentPageNumber;
    if (totalRecords < upperCount) {
      return totalRecords;
    } else {
      return upperCount;
    }
  };

  const totalPages = () => {
    if (totalRecords === 0) {
      return 0;
    }
    let totalPages = Math.ceil(totalRecords / pageSize);
    return totalPages;
  };

  return (
    <React.Fragment>
      {/* {JSON.stringify(props)}
      <p>currentPageNumber - {currentPageNumber}</p>
      <p>totalPages - {totalPages()}</p>
      <p>route - {route}</p> */}
      <div className="flex items-center justify-end my-4 text-base">
        {/* <div className="flex items-center">
          <div>
            <span className="mr-2">Page</span>
            <input
              className="appearance-none border rounded"
              min="1"
              type="number"
              onChange={goTo}
              style={{ width: "60px" }}
            />
            <span className="mr-4"> of {totalPages()}</span>
          </div>
        </div> */}
        <div className="flex items-center">
          Showing
          <button
            className="mx-2 px-1 py-1 border rounded cursor-pointer bg-light"
            disabled={currentPageNumber === 1}
            onClick={goToPreviousPage}
          >
            <i className="material-icons">navigate_before</i>
            {/* <FaChevronLeft /> */}
          </button>
          <span className="mr-1">
            {pageSize * currentPageNumber - pageSize + 1}{" "}
          </span>
          <span>- {endCount()} </span>
          <span className="mx-1">of {totalRecords}</span>
          <button
            className="ml-2 px-1 py-1 border rounded bg-light"
            disabled={currentPageNumber === totalPages()}
            onClick={goToNextPage}
          >
            <i className="material-icons">navigate_next</i>
            {/* <FaChevronRight /> */}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pagination;

// v-model="currentPageNumber"
