import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Pagination from "../../components/components-overview/custom/Pagination";
import { getSims, setCurrentPage, setStartDate, setEndDate, setSearchText, setSimStatus, getSimsSummary } from "../../redux/sims";
import { useSelector, useDispatch } from "react-redux";
import { viewStates } from "../../utils/constants";
import { toFriendlyDate } from "../../utils/helpers";
import { Button, Col, Container, FormInput, FormSelect, Row } from "shards-react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../../assets/react_dates_overrides.css";
import PageTitle from "../../components/common/PageTitle";
import ResponsiveTable from "../../components/custom/ResponsiveTable";
import SmallStats from "../../components/common/SmallStats";
import FilterCard from "../../components/custom/FilterCard";
import CsvDownload from "../../components/custom/CSVDownload";

const Customers = () => {
  const state = useSelector(state => state.sims);
  const sims = state.sims;
  const dispatch = useDispatch();
  const history = useHistory();
  let page = state.filter.currentPage;

  const tableHeaders = ["", "Code", "Phone Number", "Owner", "Network", "Serial Number", "Sim Status", "Created On"];

  function handleRouteChange(pageNumber) {
    history.push(`/sims?page=${pageNumber}`);
    dispatch(setCurrentPage(pageNumber));
  }

  const [focusedInput1, setFocusedInput1] = useState(false);
  const [focusedInput2, setFocusedInput2] = useState(false);

  useEffect(() => {
    // useless stats - just remove them
    // dispatch(getSimsSummary());
    dispatch(getSims());
  }, [page]);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <SimsListView />;
    }
  };

  const Filter = () => {
    console.log("redrawing filter");
    return (
      <div className="w-full flex items-end">
        {/* {JSON.stringify(state.filter.profileStatus)} */}
        <div className="w-1/4 mx-2">
          <label htmlFor="">Search text</label>
          <FormInput
            placeholder="Search by name/phone number"
            value={state.filter.searchText}
            onChange={e => dispatch(setSearchText(e.target.value))}
          />
        </div>
        <FormSelect className="w-1/4 mx-2" onChange={e => dispatch(setSimStatus(e.target.value))}>
          <option>Filter by status..</option>
          <option value="">All</option>
          <option value="1">Active</option>
          <option value="2">Inactive</option>
        </FormSelect>

        <div className="mx-2">
          <label htmlFor="" className="block">
            From
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.startDate} // momentPropTypes.momentObj or null,
            id="filterStartDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setStartDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({ focused }) => setFocusedInput1(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <div className="mx-2">
          <label htmlFor="" className="block">
            To
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.endDate} // momentPropTypes.momentObj or null,
            id="filterEndDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setEndDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput2} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({ focused }) => setFocusedInput2(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <Button
          className=""
          onClick={() => {
            dispatch(setCurrentPage(1));
            handleRouteChange(1);
            dispatch(getSims());
          }}
        >
          Filter
        </Button>
      </div>
    );
  };

  const SimsListView = () => {
    const sims = state.sims;
    const rowNumber = index => {
      return state.filter.pageSize * state.filter.currentPage - state.filter.pageSize + (index + 1);
    };

    return (
      <React.Fragment>
        <ResponsiveTable headerData={tableHeaders}>
          {sims.map((sim, index) => {
            return (
              <tr key={sim.id}>
                <td>{rowNumber(index)}</td>
                <td>{sim.simCode}</td>
                <td>{sim.phoneNumber}</td>
                <td>{sim.owner}</td>
                <td>{sim.network}</td>
                <td>{sim.serialNumber}</td>
                <td>{sim.simStatusDescription}</td>
                <td>{toFriendlyDate(sim.createdOn)}</td>
              </tr>
            );
          })}
        </ResponsiveTable>
        <Pagination
          pageSize={parseInt(state.filter.pageSize)}
          currentPage={parseInt(state.filter.currentPage)}
          totalRecords={state.filter.totalRecords}
          onRouteChange={handleRouteChange}
        />
      </React.Fragment>
    );
  };

  const Summary = () => {
    return (
      <Row>
        {state.summary.map((summary, index) => {
          return (
            <Col className="col-lg mb-4" key={index}>
              <SmallStats
                id={index}
                variation="1"
                // chartData={[0]}
                // chartLabels={stats.chartLabels}
                label={summary.key}
                value={summary.count}
                // percentage={stats.percentage}
                // increase={stats.increase}
                // decrease={stats.decrease}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <Col xs="6">
          <PageTitle sm="4" title="Sims" subtitle="Customer" className="text-sm-left" />
        </Col>
        {/* <Col xs="6" className="d-flex align-items-center justify-content-end">
          <Button sm="3" className="ml-2">
            <CsvDownload csvData={{ data: sims, headers: tableHeaders, filename: "customers.csv" }} />
          </Button>
        </Col> */}
      </Row>

      <Row>
        <Col>
          {FilterCard(Filter())}
          {renderView()}
        </Col>
      </Row>
    </Container>
  );
};

export default Customers;
