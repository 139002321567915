import { viewStates, setViewState } from "../utils/constants";
import { http_admin, getToken } from "../utils/axios-module";
import moment from "moment";
import {buildUrlFromQueryObject, toEndOfDay, toStartOfDay} from "../utils/helpers";
// import { ToastContainer, toast, Slide } from "react-toastify";

const initialState = {
  viewState: viewStates.IDLE,
  inwardMessages: [],
  totalRecords: 0,
  summary: [],
  filter: {
    startDate: moment().add(-7, "d"),
    endDate: moment(),
    searchText: "",
    processingStatus: "",
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10
  }
};

export function getInwardMessages() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    let filter = {
      searchText: state.inwardMessages.filter.searchText,
      startDate: toStartOfDay(state.inwardMessages.filter.startDate),
      endDate: toEndOfDay(state.inwardMessages.filter.endDate),
      currentPage: parseInt(state.inwardMessages.filter.currentPage),
      pageSize: parseInt(state.inwardMessages.filter.pageSize),
      processingStatus: parseInt(state.inwardMessages.filter.processingStatus)
    };
    let url = buildUrlFromQueryObject("", filter);
    dispatch(setViewState(viewStates.BUSY));
    console.log(`making request to /inwardmessages?${process.env.REACT_APP_CLIENT_API}`);
    http_admin
      .get(`/simtransactions/inwardmessages?${url}`)
      .then(response => {
        dispatch(setInwardMessages(response.data.data.result));
        dispatch(setTotalRecords(response.data.data.totalRecords));
        dispatch(setViewState(viewStates.RETRIEVED));
        console.table(response.data.data.result);
      })
      .catch(error => {});
  };
}

export function getMessageSummary() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/simtransactions/inwardmessages/summary`)
      .then(response => {
        dispatch(setSummary(response.data.data));
      })
      .catch(error => {});
  };
}

export function setSummary(summary) {
  return {
    type: "SET_SUMMARY",
    payload: summary
  };
}

export function setStartDate(startDate) {
  return {
    type: "SET_START_DATE",
    payload: startDate
  };
}

export function setEndDate(endDate) {
  return {
    type: "SET_END_DATE",
    payload: endDate
  };
}

export function setCurrentPage(currentPage) {
  return {
    type: "SET_CURRENT_PAGE",
    payload: currentPage
  };
}

export function setSearchText(searchText) {
  return {
    type: "SET_SEARCH_TEXT",
    payload: searchText
  };
}

export function setProcessingStatus(processingStatus) {
  return {
    type: "SET_PROCESSING_STATUS",
    payload: processingStatus
  };
}

export function setTotalRecords(totalRecords) {
  return {
    type: "SET_TOTAL_RECORDS",
    payload: totalRecords
  };
}

function setInwardMessages(inwardMessages) {
  return {
    type: "SET_INWARD_MESSAGES",
    payload: inwardMessages
  };
}

export default function inwardMessagesReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        filter: { ...state.filter, currentPage: action.payload }
      };
    case "SET_TOTAL_RECORDS":
      return {
        ...state,
        filter: { ...state.filter, totalRecords: action.payload }
      };
    case "SET_PROCESSING_STATUS":
      return {
        ...state,
        filter: { ...state.filter, processingStatus: action.payload }
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        filter: { ...state.filter, searchText: action.payload }
      };
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_INWARD_MESSAGES":
      return {
        ...state,
        inwardMessages: action.payload
      };
    case "SET_START_DATE":
      return {
        ...state,
        filter: { ...state.filter, startDate: moment(action.payload) }
      };
    case "SET_END_DATE":
      return {
        ...state,
        filter: { ...state.filter, endDate: moment(action.payload) }
      };

    default:
      return state;
  }
}
