import redux, {combineReducers, createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
// const {combineReducers, createStore} = redux;

import customersReducers from './customers.js'
import simsReducers from './sims.js'
import simsTransactionsReducers from './simstransactions.js'
import inwardMessagesReducers from './inwardMessages.js'
import otpsReducers from './otps.js'
import issuesReducers from './issues.js'
import activitiesReducers from './activities.js'
import backofficeUsersReducers from './backofficeUsers.js'
import authReducers from "./auth.js";
import settingsReducers from "./settings.js";
import feesReducers from "./fees.js";
import gatewaysReducers from './gateways.js'
import messagesReducers from './messages.js'
import { otpProvidersConversionRateReducers }  from './otpConversionRates'

const rootReducer = combineReducers({
  customers: customersReducers,
  sims: simsReducers,
  simsTransactions: simsTransactionsReducers,
  issues: issuesReducers,
  inwardMessages: inwardMessagesReducers,
  otps: otpsReducers,
  activities: activitiesReducers,
  backofficeUsers: backofficeUsersReducers,
  auth: authReducers,
  settings: settingsReducers,
  fees: feesReducers,
  gateways: gatewaysReducers,
  messages: messagesReducers,
  otpProvidersConversionRate: otpProvidersConversionRateReducers
});

const store = createStore(rootReducer, applyMiddleware(thunk))
store.subscribe(() => {
    // console.log(store.getState())
})
export default store;
