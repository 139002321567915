import React, {useEffect} from "react";
import {getDashboardData} from "../redux/customers";
import {useSelector, useDispatch} from "react-redux";
import {viewStates} from "../utils/constants";
import SummaryCard from "../components/custom/SummaryCard";
import {toFriendlyDate, toThousandCurrencyFormat} from "../utils/helpers";
import {Container} from "@material-ui/core";
import ResponsiveTable from "../components/custom/ResponsiveTable";
import {Col, Row} from "shards-react";
import PageTitle from "../components/common/PageTitle";


const tableHeaders = [
  'S/N',
  'Amount',
  'Fee',
  'Reference',
  'Customer',
  'Sender',
  'Type',
  'Recipient',
  'Status',
  'Initiated on'
]


const Dashboard = () => {
  const state = useSelector(state => state.customers);
  const dispatch = useDispatch();
  const customers = state.dashboard.customers;
  const transactions = state.dashboard.transactions;

  useEffect(() => {
    dispatch(getDashboardData());
    const autoRefresh = setInterval(() => {
      dispatch(getDashboardData());
    }, 30000);
    return () => clearInterval(autoRefresh);
  }, []);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      if (customers !== undefined) {
        return <Sections/>;
      }
      return null;
    }
  };

  const Sections = () => {
    return (
      <React.Fragment>
          <TotalSummary/>
          <TodaySummary/>
          {/*<TransactionsListView/>*/}
      </React.Fragment>
    );
  };

  const TotalSummary = () => {
    return (
      <React.Fragment>
        <h1 className="text-xl font-bold text-gray-600">Total Summary</h1>

        <div className="summary-container w-full">
          <SummaryCard title="All customers" count={customers.allTime.count}/>
          <SummaryCard
            title={transactions.allTime.allTypes.key}
            count={transactions.allTime.allTypes.count}
            sum={transactions.allTime.allTypes.sum}
            showSum={true}
          />
          <SummaryCard
            title={transactions.allTime.transfers.key}
            count={transactions.allTime.transfers.count}
            sum={transactions.allTime.transfers.sum}
            showSum={true}
          />
          <SummaryCard
            title={transactions.allTime.airtimeRecharge.key}
            sum={transactions.allTime.airtimeRecharge.sum}
            showSum={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const TodaySummary = () => {
    return (
      <div className="mt-10">
        <h1 className="text-xl font-bold text-gray-600">Today's Summary</h1>
        <div className="summary-container">
          <SummaryCard title="All customers" count={customers.today.count}/>
          <SummaryCard
            title={transactions.today.allTypes.key}
            count={transactions.today.allTypes.count}
            sum={transactions.today.allTypes.sum}
            showSum={true}
          />
          <SummaryCard
            title={transactions.today.transfers.key}
            count={transactions.today.transfers.count}
            sum={transactions.today.transfers.sum}
            showSum={true}
          />
          <SummaryCard
            title={transactions.today.airtimeRecharge.key}
            sum={transactions.today.airtimeRecharge.sum}
            showSum={true}
          />
          {/* {JSON.stringify(customers)}
          {console.log(transactions)} */}
        </div>
      </div>
    );
  };

  const TransactionsListView = () => {
    const transactionList = transactions.transactions;

    const getRecipient = ({
                            transactionType,
                            recipientPhoneNumber,
                            recipientBank,
                            recipientAccountNumber
                          }) => {
      if ([2, 8, 10].includes(transactionType)) {
        return <span>{recipientPhoneNumber}</span>;
      } else if (transactionType === 9) {
        return (
          <span>
            {recipientAccountNumber}({recipientBank})
          </span>
        );
      } else return "-";
    };

    if (transactionList.length === 0) {
      return (
        <p className="text-center text-base mt-10">
          There are no transactions.
        </p>
      );
    }

    return (
      <div className="mt-12">
        <h1 className="text-xl font-bold text-gray-600">
          Recent Transactions (last 10)
        </h1>
        <div className="">
          <ResponsiveTable headerData={tableHeaders}>
            {transactionList.map((transaction, index) => {
              return (
                <tr
                  key={transaction.id}
                  className="cursor-pointer rounded bg-white hover:bg-blue-200"
                  style={{boxShadow: "0 2px 14px 1px rgba(0, 0, 0, 0.03)"}}
                >
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    {toThousandCurrencyFormat(transaction.amount)}
                  </td>
                  <td>
                    {toThousandCurrencyFormat(transaction.fee)}
                  </td>
                  <td>
                    {transaction.transactionReference || "-"}
                  </td>
                  <td>
                    {transaction.customerName}
                  </td>
                  <td>
                    {transaction.senderPhoneNumber}
                  </td>
                  <td>
                    {transaction.transactionTypeDescription}
                  </td>
                  <td>
                    {getRecipient(transaction)}
                  </td>
                  <td>
                    {transaction.transactionStatusDescription}
                  </td>
                  <td>
                    {toFriendlyDate(transaction.initiatedOn)}
                  </td>
                </tr>
              );
            })}
          </ResponsiveTable>
        </div>
      </div>
    );
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Dashboard" subtitle="Customer"
                   className="text-sm-left"/>
      </Row>
      {renderView()}
    </Container>
  );
};

export default Dashboard;
