import React from 'react';

const Overlay = (props) => {

    const style1 = {
        margin: 'auto',
        background: 'rgb(255, 255, 255)',
        display: 'block',
        shapRendering: 'auto'
    };

  return (
      <div>
        <div
          className="overlay hidden w-full h-screen items-center justify-center bg-white fixed inset-0 opacity-50" style={{zIndex:4000}}>
          <svg
            style={style1}
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              r="36.1575"
              fill="none"
              stroke="#2ac109"
              strokeWidth="2"
            >
              <animate
                attributeName="r"
                repeatCount="indefinite"
                dur="1s"
                values="0;40"
                keyTimes="0;1"
                keySplines="0 0.2 0.8 1"
                calcMode="spline"
                begin="-0.5s"
              ></animate>
              <animate
                attributeName="opacity"
                repeatCount="indefinite"
                dur="1s"
                values="1;0"
                keyTimes="0;1"
                keySplines="0.2 0 0.8 1"
                calcMode="spline"
                begin="-0.5s"
              ></animate>
            </circle>
            <circle
              cx="50"
              cy="50"
              r="17.9147"
              fill="none"
              stroke="#46f074"
              strokeWidth="2"
            >
              <animate
                attributeName="r"
                repeatCount="indefinite"
                dur="1s"
                values="0;40"
                keyTimes="0;1"
                keySplines="0 0.2 0.8 1"
                calcMode="spline"
              ></animate>
              <animate
                attributeName="opacity"
                repeatCount="indefinite"
                dur="1s"
                values="1;0"
                keyTimes="0;1"
                keySplines="0.2 0 0.8 1"
                calcMode="spline"
              ></animate>
            </circle>
          </svg>
        </div>
        <div>
          {props.children}
        </div>
      </div>
  );
};

export default Overlay;
