import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import Pagination from "../components/components-overview/custom/Pagination"
import {
  getInwardMessages,
  setCurrentPage,
  setStartDate,
  setEndDate,
  setSearchText,
  setProcessingStatus,
  getMessageSummary
} from "../redux/inwardMessages";
import {useSelector, useDispatch} from "react-redux";
import {viewStates} from "../utils/constants";
import {toFriendlyDate} from "../utils/helpers";
import {Col, Container, FormInput, FormSelect, Row} from "shards-react";
import "react-dates/initialize";
import {SingleDatePicker} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../assets/react_dates_overrides.css";
import SummaryCard from '../components/custom/SummaryCard';
import PageTitle from "../components/common/PageTitle";
import ResponsiveTable from "../components/custom/ResponsiveTable";
import FilterCard from "../components/custom/FilterCard";

const InwardMessages = () => {
  const state = useSelector(state => state.inwardMessages);
  const dispatch = useDispatch();
  const history = useHistory();
  let page = state.filter.currentPage;

  function handleRouteChange(pageNumber) {
    history.push(`/inwardmessages?page=${pageNumber}`);
    dispatch(setCurrentPage(pageNumber));
  };

  const [focusedInput1, setFocusedInput1] = useState(false);
  const [focusedInput2, setFocusedInput2] = useState(false);

  useEffect(() => {
    dispatch(getMessageSummary());
    dispatch(getInwardMessages());
  }, [page]);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <MessagesListView/>;
    }
  };

  const Filter = () => {
    return (
      <div className="w-full flex items-end">
        {/* {JSON.stringify(state.filter.profileStatus)} */}
        <FormInput
          className="w-1/4"
          placeholder="Search by sender, sim, or message content"
          value={state.filter.searchText}
          onChange={e => dispatch(setSearchText(e.target.value))}
        />
        <FormSelect
          className="w-1/4"
          onChange={e => dispatch(setProcessingStatus(e.target.value))}
        >
          <option>Filter by status..</option>
          <option value="">All</option>
          <option value="2">Ingestion successful</option>
          <option value="3">Ingestion failed</option>
        </FormSelect>

        <div>
          <label htmlFor="" className="block">
            From
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.startDate} // momentPropTypes.momentObj or null,
            id="filterStartDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setStartDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({focused}) => setFocusedInput1(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <div>
          <label htmlFor="" className="block">
            To
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.endDate} // momentPropTypes.momentObj or null,
            id="filterEndDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setEndDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput2} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({focused}) => setFocusedInput2(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <button
          className="bg-blue-primary px-4 py-2 text-white border-none"
          onClick={() => {
            dispatch(setCurrentPage(1));
            handleRouteChange(1);
            dispatch(getInwardMessages())
          }}
        >
          Filter
        </button>
      </div>
    );
  };

  const MessagesListView = () => {
    const inwardMessages = state.inwardMessages;
    const rowNumber = index => {
      return (
        state.filter.pageSize * state.filter.currentPage -
        state.filter.pageSize +
        (index + 1)
      );
    };

    return (
      <React.Fragment>
        <ResponsiveTable
          headerData={["", "Message", "Sender", "Amount", "Status", "Gateway Sim", "ReceivedOn", "SMSFrom"]}
        >
          {inwardMessages.map((message, index) => {
            return (
              <tr
                key={message.id}
              >
                <td>
                  {rowNumber(index)}
                </td>
                <td>
                  {message.message}
                </td>
                <td>
                  {message.extractedSender}
                </td>
                <td>
                  {message.extractedAmount}
                </td>
                <td>
                  {message.processingStatusDescription}
                </td>
                <td>
                  {message.to || '-'}<br/>{message.server}
                </td>
                <td>
                  {toFriendlyDate(message.receivedOn)}
                </td>
                <td>
                  {message.from}
                </td>
              </tr>
            );
          })}
        </ResponsiveTable>
        <Pagination
          pageSize={parseInt(state.filter.pageSize)}
          currentPage={parseInt(state.filter.currentPage)}
          totalRecords={state.filter.totalRecords}
          onRouteChange={handleRouteChange}
        />
      </React.Fragment>
    );
  };

  const Summary = () => {
    return (
      <div className="summary-container">
        {state.summary.map((summary, index) => {
          return (
            <SummaryCard
              title={summary.key}
              count={summary.count}
              key={index}
            />
          );
        })}
      </div>
    );
  };


  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Inward SMS" subtitle="Management"
                   className="text-sm-left"/>
      </Row>
      <div className="my-6">
        <Summary/>
      </div>
      <Row>
        <Col>
          {FilterCard(Filter())}
          {renderView()}
        </Col>
      </Row>
    </Container>
  );
};

export default InwardMessages;
