import axios from "axios";
import { toast } from "react-toastify";

const getToken = () => {
  const user = localStorage.getItem("user");

  if (user != undefined) {
    return JSON.parse(user).token;
  }
  return "";
};

const http = axios.create({
  baseURL: `${process.env.REACT_APP_CLIENT_API}`,
  headers: {
    "content-type": "application/json"
  }
});

const http_admin = axios.create({
  baseURL: `${process.env.REACT_APP_BACKOFFICE_API}`,
  headers: {
    "content-type": "application/json"
  }
});


// declare a request interceptor
http.interceptors.request.use(
  config => {
    document.querySelector(".overlay").classList.remove("hidden");
    document.querySelector(".overlay").classList.add("flex");
    return config;
  },
  error => {
    // handle the error
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function(response) {
    document.querySelector(".overlay").classList.remove("flex");
    document.querySelector(".overlay").classList.add("hidden");
    return response;
  },
  function(error) {
        document.querySelector(".overlay").classList.remove("flex");
        document.querySelector(".overlay").classList.add("hidden");
    if (error.response) {
      if (error.response.status === 401) {

        localStorage.removeItem("user");
        console.log(toast);
        window.location = "/login"
          toast.error(
            "Your session has expired. Please login to continue."
            );

      }
      toast.error(error.response.data.message || "Error occured");

    } else if (error.request) {
      console.log(error.request);
    } else {
    }
    return Promise.reject(error);
  }
);
http_admin.interceptors.request.use(
  config => {
    document.querySelector(".overlay").classList.remove("hidden");
    document.querySelector(".overlay").classList.add("flex");
    return config;
  },
  error => {
    // handle the error
    return Promise.reject(error);
  }
);

// Add a response interceptor
http_admin.interceptors.response.use(
  function(response) {
    document.querySelector(".overlay").classList.remove("flex");
    document.querySelector(".overlay").classList.add("hidden");
    return response;
  },
  function(error) {
        document.querySelector(".overlay").classList.remove("flex");
        document.querySelector(".overlay").classList.add("hidden");

    if (error.response) {
      if (error.response.status === 401) {
        window.location = "/login";
        toast.error("Your session has expired. Please login to continue.");
      }
            toast.error(error.response.data.message || "Error occured");

    } else if (error.request) {
      console.log(error.request);
    } else {
    }
    return Promise.reject(error);
  }
);

export { http, getToken, http_admin };
