import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Spacer from "../../components/custom/Spacer";
import { FormInput } from "shards-react";
import { completePasswordReset } from "../../redux/auth";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

const PasswordReset = props => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userId = params.get("userId");
  const token = params.get("token");
  const history = useHistory();

  const MyInput = ({ field, form, ...props }) => {
    return <FormInput {...field} {...props} />;
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    )
  });

  const handleSubmit = async (values, resetForm) => {
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();
    var payload = {
      newPassword: values.newPassword,
      userId: userId,
      resetToken: token
    }
    dispatch(completePasswordReset(payload)).then(response => {
      if(response){
        history.push('/login')
        toast.success("Password reset successful.")
        resetForm()
      }
    });
  };


  const initialValues = {
    email: "",
  };

  return (
    <>
      <h1>Tingtel</h1>
      <div className="w-full h-screen flex items-center justify-center">
        <div className="w-1/4">
          <h1 className="text-center text-2xl font-semibold my-5 cursor-pointer">
            <Link to="/login" className="no-underline">
              Tingtel
            </Link>
          </h1>
          <div className="bg-white">
            <h2 className="text-xl text-center font-semibold px-4 py-4 text-black-500">
              Reset password
            </h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, resetForm) => {
                handleSubmit(values, resetForm);
              }}
            >
              {({ values, errors, handleSubmit }) => (
                <form className="w-full px-8 py-6" onSubmit={handleSubmit}>
                  {/* <p>{JSON.stringify(values)}</p> */}
                  <div className="w-full">
                    <label htmlFor="">New password</label>
                    <Field
                      name="newPassword"
                      type="password"
                      component={MyInput}
                    />
                    {errors.newPassword && (
                      <p className=" text-red-500 text-sm">
                        {errors.newPassword}
                      </p>
                    )}
                  </div>
                  <Spacer height="10px" />
                  <div className="w-full">
                    <label htmlFor="">Confirm password</label>
                    <Field
                      name="confirmPassword"
                      type="password"
                      component={MyInput}
                    />
                    {errors.confirmPassword && (
                      <p className=" text-red-500 text-sm">
                        {errors.confirmPassword}
                      </p>
                    )}
                  </div>
                  <Spacer height="10px" />

                  <div className="flex items-center justify-start">
                    <button
                      type="button"
                      className="bg-blue-primary px-4 py-2 text-white border-none w-full"
                      onClick={() => handleSubmit(values)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
