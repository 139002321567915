import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, FormTextarea, Form } from "shards-react";
import { getIssues, handleAdminComment } from "../redux/issues";
import moment from "moment";

const getCurrentUser = () => JSON.parse(localStorage.getItem("user"));

export const CustomerIssuesForm = ({ cancel, issue, closeModal }) => {
  const [user, setUser] = useState(() => getCurrentUser());
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  const canned = [
    "Completed manually. Customer showed proof of payment.",
    "Not completed. Customer unable to show proof of payment.",
    "Not completed. Customer completed another transaction instead.",
    "Resolved PIN issues.",
    "Duplicate of issue already resolved."
  ]

  const renderCannedButtons = () => {
    return canned.map(value => {
      return (
        <Button className="mb-2 text-left block btn-outline-primary" onClick={e => setText(value)}>
          {value}
        </Button>
      );
    });
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (!text) return;
    dispatch(handleAdminComment({ customerIssueId: issue.id, adminName: user.fullName, adminId: user.adminId, message: text }))
    cancel();
    closeModal();
    setTimeout(() => {
      dispatch(getIssues());
    }, 750);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-2 uppercase font-bold">Standard responses</div>
      {renderCannedButtons()}

      <div className="mb-2 mt-3 uppercase font-bold">Your comment</div>

      <FormTextarea value={text} onChange={e => setText(e.target.value)} />
      <div className="d-flex justify-center mt-3">
        <Button className="cancel-btn mr-2" onClick={cancel}>
          Back
        </Button>
        <Button disabled={!text}>
          Add Comment
        </Button>
      </div>
    </Form>
  );
};
