import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import  Input from "../../components/custom/Input";
import Spacer  from '../../components/custom/Spacer';
import { FormInput } from 'shards-react';
import { createUser, editUser } from '../../redux/backofficeUsers';
const UserForm = props => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.backofficeUsers);
  const user = state.user;

  const MyInput = ({ field, form, ...props }) => {
    return <FormInput {...field} {...props} />;
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Firstname is required"),
    lastName: Yup.string().required("Lastname is required"),
    email: Yup.string().required("Email is required"),
  });

  const handleSubmit = async(values, resetForm) => {
    console.log('hello')
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();
    console.log(values)
    dispatch(editUser(user.id, values)).then(response => {
        if(response){
          props.cleanup()
          resetForm()
        }
    });
  };

  const test = () => {
    props.updateKeys();
  };



  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email
  };

  return (
    <div>
      <h2 className="text-xl font-semibold px-4 py-4 text-left text-black-500 border-b" onClick={test}>
        Edit user
      </h2>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, {resetForm}) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({ values, errors, handleSubmit }) => (
          <form className="w-full px-8 py-6" onSubmit={handleSubmit}>
            {/* <p>{JSON.stringify(values)}</p> */}
            <div className="w-full flex items-center">
              <div className="w-1/2">
                <label htmlFor="">First name</label>
                <Field name="firstName" component={MyInput} />
                {errors.firstName && (
                  <p className="text-red-500 text-sm">{errors.firstName}</p>
                )}
              </div>
              <Spacer width="25px" />
              <div className="w-1/2">
                <label htmlFor="">Last name</label>
                <Field name="lastName" component={MyInput} />
                {errors.lastName && (
                  <p className=" text-red-500 text-sm">{errors.lastName}</p>
                )}
              </div>
            </div>
            <Spacer height="15px" />
            <div className="w-full flex items-center"></div>

            <div className="w-full">
              <label htmlFor="">Email</label>
              <Field label="Email Address" name="email" component={MyInput} />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <Spacer height="15px" />

            <div className="flex items-center justify-start mt-12">
              <button type="button"
                className="bg-blue-primary px-4 py-2 text-white border-none"
                onClick={() => handleSubmit(values)}
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
