/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useContext, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  Button,
  FormInput
} from "shards-react";
import { updateGateway } from "../../redux/gateways";
import { lowercaseFirstLetter } from "../../utils/helpers";
import { GatewayContext } from "./gatewayContext";

import GatewaySelect from "./GatewaySelect";

const UpdateGatewayPriorities = ({ detials }) => {
  const dispatch = useDispatch();
  const { priorityType } = useContext(GatewayContext);
  const options = [0, 1, 2, 3, 4, 5];
  const statusOptions = [
    { name: "Online", value: 1 },
    { name: "Offline", value: 2 },
    { name: "Error", value: 3 },
    { name: "Disabled", value: 4 },
    { name: "Seeding", value: 5 }
  ];
  const [maxAmount, setMaxAmount] = useState(detials.maxAmount);
  const [formData, setFormData] = useReducer(
    (formData, newData) => ({ ...formData, ...newData }),
    {}
  );

  const getSelectValue = (name, value) => {
    setFormData({ [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormData({});
    const updatedGatewayData = { ...detials, ...formData, maxAmount };
    dispatch(updateGateway(updatedGatewayData));
  };

  return (
    <Card small>
      <CardHeader className="border-bottom">
        <h6 className="m-0">{detials.name}</h6>
      </CardHeader>
      <CardBody>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="feEmailAddress">Prirority</label>
                    </Col>
                    <Col md="6" className="form-group">
                      <GatewaySelect
                        name={lowercaseFirstLetter(priorityType)}
                        options={options}
                        getValue={getSelectValue}
                        selected={detials[lowercaseFirstLetter(priorityType)]}
                      />
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="feInputCity">Status</label>
                    </Col>
                    <Col md="6" className="form-group">
                      <GatewaySelect
                        name={"status"}
                        getValue={getSelectValue}
                        options={statusOptions}
                        selected={detials["status"]}
                      />
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="feInputCity">Max Amount</label>
                    </Col>
                    <Col md="6" className="form-group">
                      <FormInput
                        size="sm"
                        value={maxAmount}
                        onChange={e => setMaxAmount(e.target.value)}
                        placeholder="Small input"
                        className="mb-2"
                      />
                    </Col>
                  </Row>

                  <Button type="submit">Update</Button>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default UpdateGatewayPriorities;
