import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router";
import ResponsiveTable from "../../components/custom/ResponsiveTable";
import {fetchGateways, gatewayModalAction} from "../../redux/gateways";
import GatewayModal from "./Modal";
import BodyData, {tableHeaders} from "./GatewayTableData";
import {GatewayContext} from "./gatewayContext";
import {lowercaseFirstLetter, spaceBeforeCap} from "../../utils/helpers";
import {Col, Container, Row} from "shards-react";
import PageTitle from "../../components/common/PageTitle";

const groupPriorities = (priority, gateways) => {
  let _pri = lowercaseFirstLetter(priority);
  return gateways
    .filter(gateway => gateway.gatewayFunctions.includes(priority))
    .sort((a, b) =>  a[_pri] > b[_pri] ? 1 : -1);
};

const priorities = {
  BulkAirtimeSendPriority: "BulkAirtimeSendPriority",
  AirtimeReceivePriority: "AirtimeReceivePriority",
  AirtimeSendPriority: "AirtimeSendPriority",
  CurrencySendPriority: "CurrencySendPriority",
  CurrencyReceivePriority: "CurrencyReceivePriority",
  SmsSendPriority: "SmsSendPriority",
  HlrPriority: "HlrPriority",
  BillSendPriority: "BillSendPriority"
};

const Gateways = () => {
  const {gateways} = useSelector(state => state.gateways);
  const modalToggle = useSelector(state => state.gateways.gatewayModal);

  const dispatch = useDispatch();
  const history = useHistory();

  const [modalDetails, setModalDetails] = useState(null);
  const [priorityType, setPriorityFuction] = useState("");

  const bulkSmsPriorities = groupPriorities(
    priorities.BulkAirtimeSendPriority,
    gateways
  );
  const receiveAirtimePriorites = groupPriorities(
    priorities.AirtimeReceivePriority,
    gateways
  );
  const sendAirtimePriorities = groupPriorities(
    priorities.AirtimeSendPriority,
    gateways
  );
  const currencyReceivePriorities = groupPriorities(
    priorities.CurrencyReceivePriority,
    gateways
  );
  const currencySendpriorities = groupPriorities(
    priorities.CurrencySendPriority,
    gateways
  );
  const smsSendpriorities = groupPriorities(
    priorities.SmsSendPriority,
    gateways
  );
  const hlrPriority = groupPriorities(
    priorities.HlrPriority,
    gateways
  );
  const billSendPriority = groupPriorities(
    priorities.BillSendPriority,
    gateways
  );


  const openModal = (modalDetails, priorityType) => {
    console.log({modalDetails, priorityType});
    dispatch(gatewayModalAction(true));
    setModalDetails(modalDetails);
    setPriorityFuction(priorityType);
  };

  const viewSims = ({id, name}) => {
    history.push({
      pathname: `/gateway/${id}/sims`,
      state: {name}
    });
  };

  useEffect(() => {
    dispatch(fetchGateways());
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <GatewayContext.Provider value={{priorityType}}>
        <GatewayModal
          details={modalDetails}
          open={modalToggle}
          close={() => dispatch(gatewayModalAction(false))}
        />
      </GatewayContext.Provider>

      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Gateways" subtitle="Management"
                   className="text-sm-left"/>
      </Row>
      <Row>
        <Col>
          <ResponsiveTable
            headerData={tableHeaders}
            title={spaceBeforeCap(priorities.SmsSendPriority)}
          >
            <BodyData
              data={smsSendpriorities}
              handleModal={openModal}
              handleViewSims={viewSims}
              priorityType={priorities.SmsSendPriority}
            />
          </ResponsiveTable>

          <ResponsiveTable
            headerData={tableHeaders}
            title={spaceBeforeCap(priorities.AirtimeReceivePriority)}
          >
            <BodyData
              data={receiveAirtimePriorites}
              handleModal={openModal}
              handleViewSims={viewSims}
              priorityType={priorities.AirtimeReceivePriority}
            />
          </ResponsiveTable>

          <ResponsiveTable
            headerData={tableHeaders}
            title={spaceBeforeCap(priorities.AirtimeSendPriority)}
          >
            <BodyData
              data={sendAirtimePriorities}
              handleModal={openModal}
              handleViewSims={viewSims}
              priorityType={priorities.AirtimeSendPriority}
            />
          </ResponsiveTable>

          <ResponsiveTable
            headerData={tableHeaders}
            title={spaceBeforeCap(priorities.BulkAirtimeSendPriority)}
          >
            <BodyData
              data={bulkSmsPriorities}
              handleModal={openModal}
              handleViewSims={viewSims}
              priorityType={priorities.BulkAirtimeSendPriority}
            />
          </ResponsiveTable>

          <ResponsiveTable
            headerData={tableHeaders}
            title={spaceBeforeCap(priorities.CurrencySendPriority)}
          >
            <BodyData
              data={currencySendpriorities}
              handleModal={openModal}
              handleViewSims={viewSims}
              priorityType={priorities.CurrencySendPriority}
            />
          </ResponsiveTable>

          <ResponsiveTable
            headerData={tableHeaders}
            title={spaceBeforeCap(priorities.CurrencyReceivePriority)}
          >
            <BodyData
              data={currencyReceivePriorities}
              handleModal={openModal}
              handleViewSims={viewSims}
              priorityType={priorities.CurrencyReceivePriority}
            />
          </ResponsiveTable>

          <ResponsiveTable
            headerData={tableHeaders}
            title={spaceBeforeCap(priorities.HlrPriority)}
          >
            <BodyData
              data={hlrPriority}
              handleModal={openModal}
              handleViewSims={viewSims}
              priorityType={priorities.HlrPriority}
            />
          </ResponsiveTable>

          <ResponsiveTable
            headerData={tableHeaders}
            title={spaceBeforeCap(priorities.BillSendPriority)}
          >
            <BodyData
              data={billSendPriority}
              handleModal={openModal}
              handleViewSims={viewSims}
              priorityType={priorities.BillSendPriority}
            />
          </ResponsiveTable>
        </Col>
      </Row>
    </Container>
  );
};

export default Gateways;
