import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";

import Pagination from "../components/components-overview/custom/Pagination";
import {
  getIssues,
  setCurrentPage,
  setStartDate,
  setEndDate,
  setSearchText,
  setIssueStatus,
  getIssuesSummary,
  changeIssueStatus, assignIssue
} from "../redux/issues";
import {useSelector, useDispatch} from "react-redux";
import {setViewState, viewStates} from "../utils/constants";
import {buildUrlFromQueryObject, toEndOfDay, toFriendlyDate, toInitials, toStartOfDay} from "../utils/helpers";
import {Button, Col, Container, FormInput, FormSelect, Modal, ModalBody, ModalHeader, Row} from "shards-react";
import "react-dates/initialize";
import {SingleDatePicker} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../assets/react_dates_overrides.css";
import SummaryCard from "../components/custom/SummaryCard";
import PageTitle from "../components/common/PageTitle";
import ResponsiveTable from "../components/custom/ResponsiveTable";
import {getSimsTransactions, setTotalRecords, toggleDetailModalVisibility} from "../redux/simstransactions";
import FilterCard from "../components/custom/FilterCard";
import {CustomerIssuesForm} from "./SupportIssuesForm";
import IssueTransactionDetails from "../components/custom/IssueTransactionDetails";
import moment from "moment";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {getToken, http_admin} from "../utils/axios-module";
import axios from "axios";
import TransactionHistory from "../components/custom/TransactionHistory";


const SupportIssues = () => {
  const state = useSelector(state => state.issues);

  const dispatch = useDispatch();
  const history = useHistory();
  let page = state.filter.currentPage;

  function handleRouteChange(pageNumber) {
    history.push(`/supportissues?page=${pageNumber}`);
    dispatch(setCurrentPage(pageNumber));
  }

  const status = [
    {text: "Open", value: 1},
    {text: "Closed", value: 2},
    {text: "In Progress", value: 3}
  ];

  const getCurrentUser = () => JSON.parse(localStorage.getItem("user"));

  const [focusedInput1, setFocusedInput1] = useState(false);
  const [focusedInput2, setFocusedInput2] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [attachmentStyle, setAttachmentStyle] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentAlt, setAttachmentAlt] = useState("Loading...");
  const [transactionHistory, setTransactionHistory] = useState(null);
  const [dropdownIsOpen, setDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(status[0]);
  const [listKey, setListKey] = useState(1);
  const [summaryKey, setSummaryKey] = useState(-1);
  const [buttonKey, setButtonKey] = useState(100);
  const [modalViews, setModalViews] = useState({issues: true, form: false});
  const [user, setUser] = useState(() => getCurrentUser());

  useEffect(() => {
    dispatch(setSearchText(""));
  }, []);

  useEffect(() => {
    dispatch(getIssues());
    dispatch(getIssuesSummary());
    const autoRefresh = setInterval(() => {
      dispatch(getIssues());
      dispatch(getIssuesSummary());
    }, 90000);
    return () => clearInterval(autoRefresh);
  }, [page, listKey]);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <IssuesListView key={listKey}/>;
    }
  };

  const toggleModal = () => {
    setModalViews({issues: true, form: false});
    setDropdownOpen(false);
    setModalOpen(!modalOpen);
  };

  const cleanMessage = (message) => {
    let idx = message.indexOf("Message = ");
    if (idx > 0) {
      return message.substring(idx + 10);
    }
    return message;
  };

  const initialiseIssue = issue => {
    setModalOpen(true);
    setSelectedIssue(issue);
    setSelectedIssueStatus(issue.status);
    getAttachment(issue.issueImageUrl);
    getTransactionHistory(issue.transactionReference);
  };

  const setSelectedIssueStatus = c => {
    let selectedStatus = status.find(x => x.value === c);
    setSelectedStatus(selectedStatus);
  };

  const getAttachment = async privateUrl => {
    let url = `${process.env.REACT_APP_BACKOFFICE_API}`.replace("/api/backoffice", "/api") + "/SecureStorage?url=" + encodeURIComponent(privateUrl);
    if (!privateUrl) {
      setAttachment("");
      setAttachmentAlt("Not available");
      setAttachmentStyle("h-5");
      return;
    }

    axios.get(url, {
        responseType: 'blob',
        headers: {"Authorization": `Bearer ${getToken()}`}
      }
    )
      .then(res => {
        setAttachment(URL.createObjectURL(res.data));
        setAttachmentStyle("h-40");
      })
      .catch(error => {
        console.log(error);
        setAttachment("");
        setAttachmentAlt("Not available");
        setAttachmentStyle("h-5");
      });
  };

  const getTransactionHistory = async reference => {
    if (!reference) {
      setTransactionHistory(null);
      return;
    }

    http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
    let filter = {
      TransactionReference: reference,
      PageSize: 30
    };
    let url = buildUrlFromQueryObject("", filter);
    http_admin
      .get(`/TransactionLogs?${url}`)
      .then(response => {
        setTransactionHistory(response.data.data.result);
      });
  };

  const Filter = () => {
    return (
      <div className="w-full flex items-end">
        {/* {JSON.stringify(state.filter.profileStatus)} */}
        <FormInput
          className="w-1/4"
          placeholder="Search by reporter name..."
          value={state.filter.searchText}
          onChange={e => dispatch(setSearchText(e.target.value))}
        />
        <FormSelect className="w-1/4" onChange={e => dispatch(setIssueStatus(e.target.value))}>
          <option>Filter by status..</option>
          <option value="">All</option>
          <option value="3">In Progress</option>
          <option value="2">Closed</option>
          <option value="1">Open</option>
        </FormSelect>

        <div>
          <label htmlFor="" className="block">
            From
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.startDate} // momentPropTypes.momentObj or null,
            id="filterStartDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setStartDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({focused}) => setFocusedInput1(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <div>
          <label htmlFor="" className="block">
            To
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.endDate} // momentPropTypes.momentObj or null,
            id="filterEndDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setEndDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput2} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({focused}) => setFocusedInput2(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <button
          className="bg-blue-primary px-4 py-2 text-white border-none"
          onClick={() => {
            dispatch(setCurrentPage(1));
            handleRouteChange(1);
            dispatch(getIssues());
          }}
        >
          Filter
        </button>
      </div>
    );
  };

  const IssuesListView = () => {
    const issues = state.issues;
    const rowNumber = index => {
      return state.filter.pageSize * state.filter.currentPage - state.filter.pageSize + (index + 1);
    };

    const getIssue = issue => {
      if (issue.length > 50) {
        return issue.substring(0, 50) + "...";
      }
      return issue;
    };

    const statusIndicator = issueStatus => {
      let style;
      switch (issueStatus) {
        case 1:
          // open / unresolved => red
          style = {
            color: "#ca4945",
            backgroundColor: "rgba(202,73,69,0.14)"
          };
          break;
        case 3:
          // assigned / in-progress => orange
          style = {
            color: "#e77816",
            backgroundColor: "rgba(202,116,69,0.14)"
          }
          break;
        default:
          //resolved / closed... green
          style = {
            color: "#1ac086",
            backgroundColor: "rgba(26,192,134,0.1)"
          }

      }

      return (
        <span className="px-2 py-1 uppercase text-center rounded-full inline-block font-normal text-sm"
          style={style}>
          {status.find(x => x.value === issueStatus).text}
        </span>
      );
    }

    return (
      <React.Fragment>
        <ResponsiveTable headerData={["", "Reported By", "Issue", "Reported on", "Status", "Resolved", ""]}>
          {issues.map((issue, index) => {
            return (
              <tr key={issue.id}>
                <td>{issue.id}</td>
                <td>{issue.reportedBy}</td>
                <td className="truncate">{getIssue(cleanMessage(issue.issue))}</td>
                <td>{toFriendlyDate(issue.reportedOn)}</td>
                <td>{statusIndicator(issue.status)}</td>
                <td>{issue.dateResolved ? moment(issue.dateResolved).fromNow() + " " + moment(issue.reportedOn).to(moment(issue.dateResolved)) : "-"}</td>
                <td>
                  <Button
                    className="uppercase text-sm"
                    outline
                    onClick={() => {
                      initialiseIssue(issue);
                    }}
                  >
                    Details
                  </Button>
                </td>
              </tr>
            );
          })}
        </ResponsiveTable>
        <Pagination
          pageSize={parseInt(state.filter.pageSize)}
          currentPage={parseInt(state.filter.currentPage)}
          totalRecords={state.filter.totalRecords}
          onRouteChange={handleRouteChange}
        />
      </React.Fragment>
    );
  };

  const Summary = () => {
    return (
      <div className="summary-container">
        {state.summary.map((summary, index) => {
          return <SummaryCard title={summary.key} count={summary.count} key={index}/>;
        })}
      </div>
    );
  };

  const handleStatusChange = async item => {
    setDropdownOpen(false);

    dispatch(changeIssueStatus({newStatus: item.value, issueId: selectedIssue.id}))
      .then(val => {
        dispatch(assignIssue({assignedTo: user.fullName, issueId: selectedIssue.id})).then(value => {
            if (value) {
              setSelectedStatus(item);
              setListKey(listKey + 1);
              setSummaryKey(summaryKey + 1);
              //close modal id ticket is closed
              if (item.value === 2) {
                setModalViews(({issues, form}) => ({issues: false, form: true}))
              }
            }
          }
        );
      });
  };

  const Detail = () => {
    const dropdownStyle = {
      backgroundColor: "white",
      boxShadow: "rgba(9, 30, 66, 0.13) 0px 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 4px 11px",
      maxWidth: "440px",
      minWidth: "220px",
      zIndex: "400",
      borderRadius: "4px",
      top: "45px"
    };

    const renderDropdown = () => {
      if (dropdownIsOpen) {
        return (
          <ul className="bg-white shadow-xs absolute" style={dropdownStyle}>
            {status.map((item, index) => {
              return (
                <li
                  className={(selectedIssue.status == item.value ? "hidden" : "") + " px-2 py-2 cursor-pointer hover:bg-blue-100"}
                  key={index}
                  onClick={() => handleStatusChange(item)}
                >
                  {item.text}
                </li>
              );
            })}
          </ul>
        );
      }

      return null;
    };

    const renderCustomerIssueView = () => {
      if (modalViews.issues) {
        return (
          <React.Fragment>
            <Row>
              <Col>
                <div className="w-full flex items-center mt-1">
                  <div className="w-10 h-10 bg-gray-600 rounded-full text-white flex items-center justify-center mr-2">
                    {toInitials(selectedIssue.reportedBy)}
                  </div>
                  <p className="ml-2 text-gray-600 text-base font-semibold">
                    <Link className="text-blue-primary uppercase font-semibold focus:outline-none" to={`/customer/${selectedIssue.customerId}`}>
                      {selectedIssue.reportedBy}
                    </Link>
                  </p>
                </div>
              </Col>
              <Col>
                <div className="text-right">
                  <button
                    className={
                      "font-bold px-5 py-2 text-base rounded items-center " +
                      (selectedStatus.value === 2 ? "bg-green-500 text-white" : selectedStatus.value === 3 ? "bg-orange-400 text-white" : "bg-red-500 text-white")
                    }
                    onClick={() => setDropdownOpen(!dropdownIsOpen)}
                  >
                    <span>{selectedStatus.text}</span>
                    <span>
                      <svg className="inline" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
                        <path
                          d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                          fill="currentColor"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
                {renderDropdown()}
              </Col>
            </Row>

            <Row className="mt-3 mb-1">
              <Col sm="6">
                {selectedIssue.reportedBy} wrote...
              </Col>
              <Col sm="6" className="text-right">
                {toFriendlyDate(selectedIssue.reportedOn)}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="bg-gray-200 p-4 rounded-sm text-base leading-relaxed">{cleanMessage(selectedIssue.issue)}</div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm="6">
                <div className="uppercase font-semibold">Attachments</div>
              </Col>
              <Col sm="6" className="text-right">
                {/*<div className="float-right rounded-sm w-1/2 align-content-end">*/}
                <Zoom>
                  <img src={attachment} alt={attachmentAlt} className={attachmentStyle}/>
                </Zoom>
                {/*</div>*/}
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm="6">
                <div className="uppercase font-semibold">Assigned To</div>
              </Col>
              <Col sm="6" className="text-right">
                {selectedIssue.assignedTo || "unassigned"}
              </Col>
            </Row>

            <IssueTransactionDetails transaction={selectedIssue.simTransaction}/>

            <TransactionHistory history={transactionHistory}/>

            <Row className="py-4">
              <Col sm="6">
                <div className="uppercase font-semibold">Comments</div>
              </Col>
              <Col sm="6">
                <div className="">
                  <span className="float-right comment-btn"
                        onClick={() => setModalViews(({issues, form}) => ({issues: !issues, form: !form}))}>
                    Add Comment
                  </span>
                </div>
              </Col>
            </Row>

            {selectedIssue.customerIssueComment.length > 0 && (
              <React.Fragment>
                {selectedIssue.customerIssueComment.map((comment, index) => (
                  <React.Fragment>
                    <Row className="mt-3 mb-1">
                      <Col sm="6">
                        {comment.adminId === 0 ? selectedIssue.reportedBy : comment.adminName} wrote...
                      </Col>
                      <Col sm="6" className="text-right">
                        {toFriendlyDate(comment.issueCreateDateTime)}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="12" className="">
                        <div key={index} className="bg-gray-200 p-4 mt-1 rounded-sm text-base leading-relaxed">
                          {cleanMessage(comment.message)}
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}

          </React.Fragment>
        );
      }

      if (modalViews.form) {
        return (
          <CustomerIssuesForm
            cancel={() => setModalViews(({issues, form}) => ({issues: !issues, form: !form}))}
            issue={selectedIssue}
            closeModal={toggleModal}
          />
        );
      }
    };

    return (
      <React.Fragment>
        <ModalHeader toggle={toggleModal}>
          Issue {selectedIssue.id}
        </ModalHeader>
        <ModalBody>{renderCustomerIssueView()}</ModalBody>
      </React.Fragment>
    );
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Issues" subtitle="Customer" className="text-sm-left"/>
      </Row>
      <div className="my-6">
        <Summary key={summaryKey}/>
      </div>
      <Row>
        <Col>
          {FilterCard(Filter())}
          {renderView()}
        </Col>
      </Row>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} centered>
        <Detail key={buttonKey}/>
      </Modal>
    </Container>
  );
};

export default SupportIssues;
