import { viewStates, setViewState } from "../utils/constants";
import {getToken, http_admin, http} from "../utils/axios-module";
import moment from "moment";
import {buildUrlFromQueryObject, toEndOfDay, toStartOfDay} from "../utils/helpers";
import { ToastContainer, toast, Slide } from "react-toastify";

const initialState = {
  viewState: viewStates.IDLE,
  transactions: [],
  totalRecords: 0,
  detailModalIsVisible: false,
  summary: [],
  filter: {
    startDate: moment().add(-7, "d"),
    endDate: moment(),
    searchText: "",
    transactionType: "",
    network: null,
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10,
    status: "",
    minValue:"",
    maxValue:""
  }
};

export function getSimsTransactions() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    let filter = {
      searchText: state.simsTransactions.filter.searchText,
      startDate: toStartOfDay(state.simsTransactions.filter.startDate),
      endDate: toEndOfDay(state.simsTransactions.filter.endDate),
      currentPage: parseInt(state.simsTransactions.filter.currentPage),
      pageSize: parseInt(state.simsTransactions.filter.pageSize),
      type: parseInt(state.simsTransactions.filter.transactionType),
      status: parseInt(state.simsTransactions.filter.status),
      minValue: state.simsTransactions.filter.minValue,
      maxValue: state.simsTransactions.filter.maxValue,
      MccMnc: parseInt(state.simsTransactions.filter.network)
    };
    let url = buildUrlFromQueryObject("", filter);
    dispatch(setViewState(viewStates.BUSY));
    console.log(
      `making request to /transactions?${process.env.REACT_APP_CLIENT_API}`
    );
    http_admin
      .get(`/simtransactions?${url}`)
      .then(response => {
        dispatch(setsimsTransactions(response.data.data.result));
        dispatch(setTotalRecords(response.data.data.totalRecords));
        dispatch(setViewState(viewStates.RETRIEVED));
       // console.table(response.data.data.result);
      })
      .catch(error => {
        ;
      });
  };
}

export function getTransactionSummary() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/simtransactions/summary`)
      .then(response => {
        dispatch(setSummary(response.data.data));
      })
      .catch(error => {
        ;
      });
  };
}
export function doOverrideTransaction(id) {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      http
        .patch(`/simtransactions/${id}/retry`)
        .then(response => {
          resolve(true);
          toast.success(response.data.message || "Operation successful.");
        })
        .catch(error => {
          resolve(false);
        });
      });
    };
}

export function setSummary(summary) {
  return {
    type: "SET_SUMMARY",
    payload: summary
  };
}

export function setStartDate(startDate) {
  return {
    type: "SET_START_DATE",
    payload: startDate
  };
}
export function toggleDetailModalVisibility() {
  return {
    type: "TOGGLE_DETAIL_MODAL_VISIBILITY",
    // payload: isVisible
  };
}

export function setEndDate(endDate) {
  return {
    type: "SET_END_DATE",
    payload: endDate
  };
}

export function setCurrentPage(currentPage) {
  return {
    type: "SET_CURRENT_PAGE",
    payload: currentPage
  };
}

export function setSearchText(searchText) {
  return {
    type: "SET_SEARCH_TEXT",
    payload: searchText
  };
}

export function setTransactionType(transactionType) {
  return {
    type: "SET_TRANSACTION_TYPE",
    payload: transactionType
  };
}
export function setNetwork(network) {
  return {
    type: "SET_NETWORK",
    payload: network
  };
}
export function setTransactionStatus(status) {
  return {
    type: "SET_TRANSACTION_STATUS",
    payload: status
  };
}

export function setTotalRecords(totalRecords) {
  return {
    type: "SET_TOTAL_RECORDS",
    payload: totalRecords
  };
}

function setsimsTransactions(transactions) {
  return {
    type: "SET_TRANSACTIONS",
    payload: transactions
  };
}
export function setMinValue(minValue) {
  return {
    type: "SET_MIN_VALUE",
    payload: minValue
  };
}

export function setMaxValue(maxValue) {
  return {
    type: "SET_MAX_VALUE",
    payload: maxValue
  };
}

export default function simsTransactionsReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload
      };
    case "SET_CURRENT_PAGE":
     // debugger
      return {
        ...state,
        filter: { ...state.filter, currentPage: action.payload }
      };
    case "SET_TOTAL_RECORDS":
      return {
        ...state,
        filter: { ...state.filter, totalRecords: action.payload }
      };
    case "SET_TRANSACTION_TYPE":
      return {
        ...state,
        filter: { ...state.filter, transactionType: action.payload }
      };
    case "SET_NETWORK":
      return {
        ...state,
        filter: { ...state.filter, network: action.payload }
      };
    case "SET_TRANSACTION_STATUS":
      return {
        ...state,
        filter: { ...state.filter, status: action.payload }
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        filter: { ...state.filter, searchText: action.payload }
      };
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_TRANSACTIONS":
      return {
        ...state,
        transactions: action.payload
      };
      case "SET_MIN_VALUE":
        return {
          ...state,
          filter: { ...state.filter, minValue: action.payload}

        };
        case "SET_MAX_VALUE":
          return {
            ...state,
            filter: { ...state.filter, maxValue: action.payload}
          };
    case "SET_START_DATE":
      return {
        ...state,
        filter: { ...state.filter, startDate: moment(action.payload) }
      };
    case "SET_END_DATE":
      return {
        ...state,
        filter: { ...state.filter, endDate: moment(action.payload) }
      };
    case "TOGGLE_DETAIL_MODAL_VISIBILITY":
      return {
        ...state,
        detailModalIsVisible: !state.detailModalIsVisible
      };

    default:
      return state;
  }
}
