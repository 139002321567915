import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import Pagination from "../../components/components-overview/custom/Pagination";
import {
  getSimsTransactions,
  setCurrentPage,
  setStartDate,
  setEndDate,
  setSearchText,
  setTransactionType,
  toggleDetailModalVisibility,
  getTransactionSummary,
  setTransactionStatus,
  doOverrideTransaction,
  setMinValue,
  setMaxValue,
  setNetwork
} from "../../redux/simstransactions";
import { useSelector, useDispatch } from "react-redux";
import { viewStates } from "../../utils/constants";
import {buildUrlFromQueryObject, toFriendlyDate, toThousandCurrencyFormat} from "../../utils/helpers";
import { Button, Col, Container, FormInput, FormSelect, Row, Modal, ModalHeader, ModalBody } from "shards-react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../../assets/react_dates_overrides.css";
import "react-responsive-modal/styles.css";
import SummaryCard from "../../components/custom/SummaryCard";
import ConfirmationDialog from "../../components/custom/ConfirmationDialog";
import PageTitle from "../../components/common/PageTitle";
import ResponsiveTable from "../../components/custom/ResponsiveTable";
import FilterCard from "../../components/custom/FilterCard";
import {getToken, http_admin} from "../../utils/axios-module";
import TransactionHistory from "../../components/custom/TransactionHistory";

const SimsTransactions = () => {
  const state = useSelector(state => state.simsTransactions);
  const transactions = state.transactions;
  console.log({transactions})
  const csvHeader =["Amount",	"Fee",	"Reference",	"Customer",	"Sender", "Type", "Recipient",	"Status", "Initiated on"]
  const dispatch = useDispatch();
  const history = useHistory();
  let page = state.filter.currentPage;
  const [selectedTransaction, setSelectedTransaction] = React.useState(null);

  function handleRouteChange(pageNumber) {
    history.push(`/simstransactions?page=${pageNumber}`);
    dispatch(setCurrentPage(pageNumber));
  }

  const [focusedInput1, setFocusedInput1] = useState(false);
  const [focusedInput2, setFocusedInput2] = useState(false);
  const [listKey, setListKey] = useState(1);
  const [summaryKey, setSummaryKey] = useState(-1);
  const [transactionHistory, setTransactionHistory] = useState(null);
  const childRef = useRef();

  useEffect(() => {
    dispatch(getTransactionSummary());
    dispatch(getSimsTransactions());
  }, [page]);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <SimsTransactionsListView />;
    }
  };

  const toggleModal = () => {
    dispatch(toggleDetailModalVisibility());
  };

  const getTransactionHistory = async reference => {
    if (!reference) {
      setTransactionHistory(null);
      return;
    }

    http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
    let filter = {
      TransactionReference: reference,
      PageSize: 30
    };
    let url = buildUrlFromQueryObject("", filter);
    http_admin
      .get(`/TransactionLogs?${url}`)
      .then(response => {
        setTransactionHistory(response.data.data.result);
      });
  };

  const Filter = () => {
    return (
      <Row>
        <Col sm="2">
          <label htmlFor="">Search text</label>
          <FormInput
            placeholder="Search by name/reference/phone number"
            value={state.filter.searchText}
            onChange={e => dispatch(setSearchText(e.target.value))}
          />
        </Col>

        <Col sm="2">
          <label htmlFor="">Status</label>
          <FormSelect onChange={e => dispatch(setTransactionStatus(e.target.value))}>
            <option>Filter by status..</option>
            <option value="1">Pending</option>
            <option value="6">Awaiting Payment</option>
            <option value="7">Payment Received</option>
            <option value="10">Processing Payment</option>
            <option value="11">Returning Payment</option>
            <option value="13">Return Failed</option>
            <option value="12">Returned</option>
            <option value="14">Cancelled</option>
            <option value="15">Expired</option>
            <option value="4">Failed</option>
            <option value="3">Completed</option>
          </FormSelect>
        </Col>

        <Col sm="2">
          <label htmlFor="">Type</label>
          <FormSelect onChange={e => dispatch(setTransactionType(e.target.value))}>
            <option>Filter by type..</option>
            <option value="2">Airtime Buy</option>
            <option value="8">Airtime Transfer</option>
            <option value="10">Airtime QR</option>
            <option value="9">Airtime Sell</option>
            <option value="1">Airtime Bills</option>
            <option value="12">Airtime Funding</option>
            <option value="14">Gift Card</option>
            <option value="11">Partner Airtime Buy</option>
          </FormSelect>
        </Col>
        <Col sm="2">
          <label htmlFor="">Network</label>
          <FormSelect onChange={e => dispatch(setNetwork(e.target.value))}>
            <option>Filter by network..</option>
            <option value="62130">MTN</option>
            <option value="62120">AIRTEL</option>
            <option value="62160">9MOBILE</option>
            <option value="62150">GLO</option>
          </FormSelect>
        </Col>
        <Col sm="2">
          <div className="d-flex">
          <label htmlFor="" className="mr-3">Min Value</label>

          <label htmlFor="" className="">Max Value</label>
          </div>

          <div className="d-flex">
          <FormInput
            placeholder="Min. Value"
            value={state.filter.minValue}
            onChange={e => dispatch(setMinValue(e.target.value))}
          />
          <FormInput
            placeholder="Max. Value"
            value={state.filter.maxValue}
            onChange={e => dispatch(setMaxValue(e.target.value))}
          />
          </div>

        </Col>

        <Col sm="3">
          <div className="d-flex justify-content-aroun">
            <label htmlFor="" className="mr-5">
              From
            </label>
            <label htmlFor="" className="ml-5 pl-4">
              To
            </label>
          </div>
          <div className="d-flex">
            <SingleDatePicker
              numberOfMonths={1}
              isOutsideRange={() => false}
              date={state.filter.startDate} // momentPropTypes.momentObj or null,
              id="filterStartDate" // PropTypes.string.isRequired,
              onDateChange={date => {
                dispatch(setStartDate(date))
              }} // PropTypes.func.isRequired,
              focused={focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={({ focused }) => setFocusedInput1(focused)} // PropTypes.func.isRequired,
            />

            <SingleDatePicker
              numberOfMonths={1}
              isOutsideRange={() => false}
              date={state.filter.endDate} // momentPropTypes.momentObj or null,
              id="filterEndDate" // PropTypes.string.isRequired,
              onDateChange={date => {
                dispatch(setEndDate(date))
              }} // PropTypes.func.isRequired,
              focused={focusedInput2} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={({ focused }) => setFocusedInput2(focused)} // PropTypes.func.isRequired,
            />
          </div>
        </Col>

        <Col sm="1" className="d-flex">
          <Button
            className=" align-self-end"
            onClick={() => {
              dispatch(setCurrentPage(1));
              handleRouteChange(1);
              dispatch(getSimsTransactions());
            }}
          >
            Filters
          </Button>
        </Col>
      </Row>
    );
  };

  const SimsTransactionsListView = () => {
    const transactions = state.transactions;
    const rowNumber = index => {
      return state.filter.pageSize * state.filter.currentPage - state.filter.pageSize + (index + 1);
    };

    const getRecipient = ({ transactionType, recipientPhoneNumber, recipientNetwork, recipientBank, recipientAccountNumber, partner, partnerCustomerId }) => {
      if ([2, 8, 10, 11].includes(transactionType)) {
        return <span>{recipientNetwork} <br/>{recipientPhoneNumber}</span>;
      } else if (transactionType === 9) {
        return (
          <span>
            {recipientBank} <br/>{recipientAccountNumber}
          </span>
        );
      } else if (transactionType === 12) {
        return (
          <span>
            {partner} <br/>{partnerCustomerId}
          </span>
        );
      } else return "-";
    };

    return (
      <React.Fragment>
        <ConfirmationDialog ref={childRef} />
        <ResponsiveTable
          headerData={["", "Debited", "Fee", "Transferred", "Customer", "Sender", "Type", "Recipient", "Status", "Initiated on", ""]}
        >
          {transactions.map((transaction, index) => {
            return (
              <tr
                key={transaction.id}
                className="rounded bg-white hover:bg-blue-200"
                style={{ boxShadow: "0 2px 14px 1px rgba(0, 0, 0, 0.03)" }}
              >
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{rowNumber(index)}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{toThousandCurrencyFormat(transaction.amount)}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3 whitespace-no-wrap">{toThousandCurrencyFormat(transaction.fee)}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{toThousandCurrencyFormat(transaction.netAmount)}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{transaction.customerName}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{transaction.senderNetwork} <br/>{transaction.senderPhoneNumber}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{transaction.transactionTypeDescription}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{getRecipient(transaction)}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">
                  <span
                    className="px-2 py-1 uppercase text-center rounded-full inline-block font-normal text-sm"
                    style={statusStyle(transaction.transactionStatus)}
                  >
                    {transaction.transactionStatusDescription}
                  </span>
                </td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{toFriendlyDate(transaction.initiatedOn)}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">
                  <Button
                    className="cursor-pointer uppercase text-sm"
                    outline
                    onClick={() => showDetails(transaction)}
                  >
                    Details
                  </Button>
                  {[6].includes(transaction.transactionStatus) && transaction.senderNetwork === "GLO" ? (
                    <div className="uppercase text-blue-primary text-sm mt-2">
                      <button
                        className="cursor-pointer"
                        onClick={() => confirmOverride(transaction.id)}
                      >
                        OVERRIDE
                      </button>
                    </div>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </ResponsiveTable>
        <Pagination
          pageSize={parseInt(state.filter.pageSize)}
          currentPage={parseInt(state.filter.currentPage)}
          totalRecords={state.filter.totalRecords}
          onRouteChange={handleRouteChange}
        />
      </React.Fragment>
    );
  };

  function showDetails(transaction) {
    dispatch(toggleDetailModalVisibility());
    setSelectedTransaction(transaction);
    getTransactionHistory(transaction.transactionReference);
  }

  const confirmOverride = id => {
    childRef.current.open("Override Transaction", "Are you sure you want to override this transaction to complete the transfer?").then(result => {
      if (result) {
        dispatch(doOverrideTransaction(id)).then(value => {
          if (value) {
            dispatch(getSimsTransactions());
            // setListKey(listKey + 1);
            // setSummaryKey(summaryKey + 1);
          }
        });
      }
    });
  };

  const Summary = () => {
    return (
      <div className="summary-container">
        {state.summary.map((summary, index) => {
          return (
            <SummaryCard title={summary.key} count={summary.count} sum={summary.sum} details={summary.details} key={index} showSum={true} />
          );
        })}
        {/* <SummaryCard title="total" value={getTotal()} /> */}
      </div>
    );
  };

  const statusStyle = status => {
    let result;
    if ([4, 8, 9, 13, 14].includes(status)) {
      result = {
        color: "#ca4945",
        backgroundColor: "rgba(202,73,69,0.14)"
      };
    } else if ([12].includes(status)) {
      result = {
        color: "#a4a4a4",
        backgroundColor: "rgba(186,186,186,0.14)"
      };
    } else if ([1, 6].includes(status)) {
      result = {
        color: "#f9c012",
        backgroundColor: "rgba(249,192,18,0.14)"
      };
    } else if (status === 3) {
      result = {
        color: "#1ac086",
        backgroundColor: "rgba(26,192,134,0.1)"
      };
    } else {
      result = {
        color: "#377ff7",
        backgroundColor: "rgba(55,127,247,0.1)"
      };
    }

    return result;
  };

  const TransactionDetail = () => {
    const getSelection = () => {
      const type = selectedTransaction.transactionType;
      if (type === 2) {
        return (
          <p className="text-lg">
            <span className="inline-block leading-4">
              {selectedTransaction.recipientNetwork}
              <br />
              <span className="text-base">{selectedTransaction.recipientPhoneNumber}</span>
            </span>
          </p>
        );
      }
      if ([8, 10].includes(type)) {
        return (
          <p className="text-lg">
            <span className="inline-block text-right align-middle p-2 leading-4">
              {selectedTransaction.senderNetwork}
              <br />
              <span className="text-base">{selectedTransaction.senderPhoneNumber}</span>
            </span>{" "}
            to
            <span className="inline-block text-left align-middle p-2 leading-4">
              {selectedTransaction.recipientNetwork}
              <br />
              <span className="text-base">{selectedTransaction.recipientPhoneNumber}</span>
            </span>
          </p>
        );
      }
      if (type === 9) {
        return (
          <p className="text-lg">
            <span className="inline-block text-right align-middle p-2 leading-4">
              {selectedTransaction.senderNetwork}
              <br />
              <span className="text-base">{selectedTransaction.senderPhoneNumber}</span>
            </span>{" "}
            to
            <span className="inline-block text-left align-middle p-2 leading-4">
              {selectedTransaction.recipientBank}
              <br />
              <span className="text-base">{selectedTransaction.recipientAccountNumber}</span>
            </span>
          </p>
        );
      }
      if (type === 11) {
        return (
          <p className="text-lg">
            <span className="inline-block text-right align-middle p-2 leading-4">
              {selectedTransaction.partner}
              <br />
            </span>{" "}
            to
            <span className="inline-block text-left align-middle p-2 leading-4">
              {selectedTransaction.recipientNetwork}
              <br />
              <span className="text-base">{selectedTransaction.recipientPhoneNumber}</span>
            </span>
          </p>
        );
      }
      if (type === 12) {
        return (
          <p className="text-lg">
            <span className="inline-block text-right align-middle p-2 leading-4">
              {selectedTransaction.senderNetwork}
              <br />
              <span className="text-base">{selectedTransaction.senderPhoneNumber}</span>
            </span>{" "}
            to
            <span className="inline-block text-left align-middle p-2 leading-4">
              {selectedTransaction.partner}
              <br />
              <span className="text-base">{selectedTransaction.partnerCustomerId}</span>
            </span>
          </p>
        );
      }
      if (type === 1) {
        // Billpay
        return (
          <p className="text-lg">
            <span className="inline-block text-center align-middle p-2 leading-4">
              {selectedTransaction.billerCategory} / {selectedTransaction.billerName}
            </span>
          </p>
        );
      }
      if (type === 14) {
        // Gift Card
        return (
          <p className="text-lg">
            <span className="inline-block text-center align-middle p-2 leading-4">
              {selectedTransaction.giftCardQuantity} x {selectedTransaction.giftCard} {selectedTransaction.giftCardUnitPrice}
              <br />
              <span className="text-base">{selectedTransaction.giftCardQuantity} @{selectedTransaction.giftCardUnitPrice} = {selectedTransaction.giftCardValue}</span>
              <br />
              <span className="text-sm">(exchange rate: {selectedTransaction.exchangeRate})</span>
            </span>
            to
            <span className="inline-block text-left align-middle p-2 leading-4">
              {selectedTransaction.giftCardBeneficiaryEmail}
            </span>
          </p>
        );
      }
    };

    const toSendingGatewayLink = transaction => {
      if (transaction.currentSendingGateway == null) {
        return "-";
      }

      let ref;
      if (transaction.giftCardReferenceNumber || transaction.billerTransactionReference) {
        ref = `ref: ${transaction.giftCardReferenceNumber || transaction.billerTransactionReference}`;
      }

      let link;
      if (transaction.currentSendingGateway.toLowerCase().indexOf("paystack") > -1) {
        // link = `https://dashboard.paystack.com/#/transfers?reference=${transaction.transactionReference}`;
        link = `https://dashboard.paystack.com/#/search?model=transactions&query=${transaction.transactionReference}`;
      } else if (transaction.currentSendingGateway.toLowerCase().indexOf("flutterwave") > -1) {
        link = `https://dashboard.flutterwave.com/dashboard/search/${transaction.transactionReference}`;
      }
      if (link) {
        return (
          <React.Fragment>
            <a href={link} className="text-blue-primary" target="_new">
              {transaction.currentSendingGateway}
            </a>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {transaction.currentSendingGateway}
            <span className="text-base inline-block break-all">{ref}</span>
          </React.Fragment>
        );
      }
    };

    const toReceivingGatewayLink = transaction => {
      let link;
      if (transaction.currentReceivingGateway == null) {
        return "-";
      }
      if (transaction.currentReceivingGateway.toLowerCase().indexOf("paystack") > -1) {
        //  link = `https://dashboard.paystack.com/#/transactions?reference=${transaction.transactionReference}`;
        link = `https://dashboard.paystack.com/#/search?model=transactions&query=${transaction.transactionReference}`;
      } else if (transaction.currentReceivingGateway.toLowerCase().indexOf("flutterwave") > -1) {
        link = `https://dashboard.flutterwave.com/dashboard/search/${transaction.transactionReference}`;
      }
      if (link) {
        return (
          <a href={link} className="text-blue-primary" target="_new">
            {transaction.currentReceivingGateway}
          </a>
        );
      } else {
        return transaction.currentReceivingGateway + " " + selectedTransaction.gatewaySim;
      }
    };

    if (selectedTransaction !== null) {
      return (
        <React.Fragment>
          <ModalHeader toggle={toggleModal} className="text-lg">
            Transaction Details
          </ModalHeader>
          <ModalBody>
            <div className="text-center mb-5 ">
              <p className="text-2xl font-bold text-gray-600">{selectedTransaction.transactionTypeDescription}</p>
              <div>{getSelection()}</div>
              <div className="mt-2">{}</div>
              <span
                className="px-4 py-1 uppercase rounded-full inline-block mt-2 font-normal text-base"
                style={statusStyle(selectedTransaction.transactionStatus)}
              >
                {selectedTransaction.transactionStatusDescription}
              </span>
            </div>
            <div className="mx-auto ml-5">
              <Row className="mb-3">
                <Col>
                  <p className="uppercase font-semibold">Debited</p>
                  <p className="mt-1 text-lg">{toThousandCurrencyFormat(selectedTransaction.amount)}</p>
                </Col>
                <Col>
                  <p className="uppercase font-semibold">Transferred</p>
                  <p className="mt-1 text-lg">{toThousandCurrencyFormat(selectedTransaction.netAmount)}</p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <p className="uppercase font-semibold">Fee</p>
                  <p className="mt-1 text-lg">{toThousandCurrencyFormat(selectedTransaction.fee)}</p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <p className="uppercase font-semibold">Customer</p>
                  <p className="text-blue-primary mt-1 text-lg">
                    <Link to={`/customer/${selectedTransaction.customerId}`} className="focus:outline-none">
                      {selectedTransaction.customerName || "-"}
                    </Link>
                  </p>
                </Col>
                <Col className="w-1/2">
                  <p className="uppercase font-semibold">Reference</p>
                  <p className="mt-1 text-base">{selectedTransaction.transactionReference || "-"}</p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <p className="uppercase font-semibold">Initiated on</p>
                  <p className="mt-1 text-lg">{toFriendlyDate(selectedTransaction.initiatedOn) || "-"}</p>
                </Col>
                {selectedTransaction.completedOn !== "0001-01-01T00:00:00" ? (
                  <Col>
                    <p className="uppercase font-semibold">Completed on</p>
                    <p className="mt-1 text-lg">{toFriendlyDate(selectedTransaction.completedOn) || "-"}</p>
                  </Col>
                ) : null}
              </Row>
            </div>
            <Row className="mb-2">
              <Col>
                <p className="uppercase font-semibold">Receiving Gateway</p>
                <p className="mt-1 text-lg">{toReceivingGatewayLink(selectedTransaction)}</p>
              </Col>
              <Col>
                <p className="uppercase font-semibold">Sending Gateway</p>
                <p className="mt-1 text-lg">{toSendingGatewayLink(selectedTransaction)}</p>
              </Col>
            </Row>

            <TransactionHistory history={transactionHistory}/>

          </ModalBody>
        </React.Fragment>
      );
    }
  };

  return (
    <Container fluid className="main-content-container px-4 shadow-none">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">

        <Col xs="6">
        <PageTitle sm="4" title="Transactions" subtitle="Customer" className="text-sm-left" />
        </Col>
        {/* <Col xs="6" className="d-flex align-items-center justify-content-end">
          <Button sm="3" className="ml-2">
            <CsvDownload csvData={{ data: transactions, headers: csvHeader, filename: "transactions.csv" }} />
          </Button>
        </Col> */}
      </Row>
      <div className="my-6">
        <Summary />
      </div>
      <Row>
        <Col>
          {FilterCard(Filter())}
          {renderView()}
        </Col>
      </Row>
      <div>
        <Modal open={state.detailModalIsVisible} toggle={toggleModal} centered>
          {TransactionDetail()}
        </Modal>
      </div>
    </Container>
  );
};

export default SimsTransactions;
