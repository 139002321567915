import React from "react";
import PropTypes from "prop-types";
import {Container, Row, Col} from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import store from "../redux/index";

import {Provider} from "react-redux";
import Auth from './Auth';
import {ToastContainer, toast, Slide} from 'react-toastify';
import MainFooter from "../components/layout/MainFooter";

const DefaultLayout = ({children, noNavbar, noFooter}) => (
  <Provider store={store}>
    <ToastContainer
      autoClose={5000}
      position={toast.POSITION.BOTTOM_CENTER}
      transition={Slide}
      hideProgressBar={true}
    />

    <Auth>
      <Container fluid>
        <Row>
          <MainSidebar/>
          <Col
            className="main-content p-0"
            lg={{size: 10, offset: 2}}
            md={{size: 9, offset: 3}}
            sm="12"
            tag="main"
          >
            {!noNavbar && <MainNavbar/>}
            {children}
            {!noFooter && <MainFooter/>}
          </Col>
        </Row>
      </Container>
    </Auth>
  </Provider>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
