import React from "react";

const CustomFileUpload = ({ className, label, onChange, disabled }) => (
  <div className={"custom-file "+className}>
    <input type="file" className="custom-file-input z-0" id="customFile2" onChange={onChange} disabled={disabled}/>
    <label className="custom-file-label z-0" htmlFor="customFile2">
      {label}
    </label>
  </div>
);

export default CustomFileUpload;
