import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import Pagination from "../components/components-overview/custom/Pagination"
import {
  getActivities,
  setCurrentPage,
  setStartDate,
  setEndDate,
  setSearchText,
  setActivityType
} from "../redux/activities";
import {useSelector, useDispatch} from "react-redux";
import {viewStates} from "../utils/constants";
import {toFriendlyDate} from "../utils/helpers";
import {Col, Container, FormInput, FormSelect, Row} from "shards-react";
import "react-dates/initialize";
import {SingleDatePicker} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../assets/react_dates_overrides.css";
import PageTitle from "../components/common/PageTitle";
import ResponsiveTable from "../components/custom/ResponsiveTable";
import FilterCard from "../components/custom/FilterCard";

const Activities = () => {
  const state = useSelector(state => state.activities);
  const dispatch = useDispatch();
  const history = useHistory();
  let page = state.filter.currentPage;

  function handleRouteChange(pageNumber) {
    history.push(`/activities?page=${pageNumber}`);
    dispatch(setCurrentPage(pageNumber));
  };

  const [focusedInput1, setFocusedInput1] = useState(false);
  const [focusedInput2, setFocusedInput2] = useState(false);

  useEffect(() => {
    dispatch(getActivities());
  }, [page]);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <ActivitiesListView/>;
    }
  };

  const Filter = () => {
    return (
      <div className="w-full flex items-end">
        {/* {JSON.stringify(state.filter.profileStatus)} */}
        <FormInput
          className="w-1/4"
          placeholder="Search by user name"
          value={state.filter.searchText}
          onChange={e => dispatch(setSearchText(e.target.value))}
        />
        <FormSelect
          className="w-1/4"
          onChange={e => dispatch(setActivityType(e.target.value))}
        >
          <option>Filter by status..</option>
          <option value="">All</option>
          <option value="1">Issue Reported</option>
          <option value="2">Registration Initiated</option>
          <option value="3">Email Confirmed</option>
          <option value="4">Password Reset Initiated</option>
          <option value="5">Password Reset Completed</option>
          <option value="6">User Logged In</option>
          <option value="7">OTP Verified</option>
          <option value="8">OTP Resent</option>
          <option value="9">Pin Set</option>
          <option value="10">Pin Reset</option>
          <option value="11">Sim Added</option>
          <option value="12">Issue Deleted</option>
          <option value="13">Sim Activated</option>
          <option value="14">Sim Deactivated</option>
          <option value="15">Airtime Transfer Initiated</option>
          <option value="16">Airtime to Cash Initiated</option>
          <option value="17">Airtime Purchase Initiated</option>
        </FormSelect>

        <div>
          <label htmlFor="" className="block">
            From
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.startDate} // momentPropTypes.momentObj or null,
            id="filterStartDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setStartDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({focused}) => setFocusedInput1(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <div>
          <label htmlFor="" className="block">
            To
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.endDate} // momentPropTypes.momentObj or null,
            id="filterEndDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setEndDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput2} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({focused}) => setFocusedInput2(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <button
          className="bg-blue-primary px-4 py-2 text-white border-none"
          onClick={() => {
            dispatch(setCurrentPage(1));
            handleRouteChange(1);
            dispatch(getActivities())
          }}
        >
          Filter
        </button>
      </div>
    );
  };


  const ActivitiesListView = () => {
    const activities = state.activities;
    const rowNumber = index => {
      return (
        state.filter.pageSize * state.filter.currentPage -
        state.filter.pageSize +
        (index + 1)
      );
    };

    return (
      <React.Fragment>
        <ResponsiveTable
          headerData={["", "Performed on", "User", "Action", "IP Address"]}
        >
          {activities.map((activity, index) => {
            return (
              <tr
                key={activity.id}
              >
                <td>
                  {rowNumber(index)}
                </td>
                <td>
                  {toFriendlyDate(activity.performedOn)}
                </td>
                <td>
                  {activity.customer || '-'}
                </td>
                <td>
                  {activity.actionDescription}
                </td>
                <td>
                  {activity.ipAddress}
                </td>
              </tr>
            );
          })}
        </ResponsiveTable>
        <Pagination
          pageSize={parseInt(state.filter.pageSize)}
          currentPage={parseInt(state.filter.currentPage)}
          totalRecords={state.filter.totalRecords}
          onRouteChange={handleRouteChange}
        />
      </React.Fragment>
    );
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Activities" subtitle="Customer"
                   className="text-sm-left"/>
      </Row>
      <Row>
        <Col>
          {FilterCard(Filter())}
          {renderView()}
        </Col>
      </Row>
    </Container>
  );
};

export default Activities;
