import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import Pagination from "../../components/components-overview/custom/Pagination";
import {
  getCustomersSummary,
  getCustomers,
  setCurrentPage,
  setStartDate,
  setEndDate,
  setSearchText,
  setProfileStatus
} from "../../redux/customers";
import { useSelector, useDispatch } from "react-redux";
import { viewStates } from "../../utils/constants";
import { toFriendlyDate, toThousandCurrencyFormat } from "../../utils/helpers";
import { Button, Col, Container, FormInput, FormSelect, Row } from "shards-react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../../assets/react_dates_overrides.css";
import SummaryCard from "../../components/custom/SummaryCard";
import ResponsiveTable from "../../components/custom/ResponsiveTable";
import PageTitle from "../../components/common/PageTitle";
import FilterCard from "../../components/custom/FilterCard";
import moment from "moment";

const Customers = () => {
  const state = useSelector(state => state.customers);
  const dispatch = useDispatch();
  const history = useHistory();
  let page = state.filter.currentPage;

  function handleRouteChange(pageNumber) {
    history.push(`/customers?page=${pageNumber}`);
    dispatch(setCurrentPage(pageNumber));
  }

  const [focusedInput1, setFocusedInput1] = useState(false);
  const [focusedInput2, setFocusedInput2] = useState(false);

  useEffect(() => {
    dispatch(getCustomersSummary());
    dispatch(getCustomers());
  }, [page]);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <CustomersListView />;
    }
  };

  const resetFilters = () => {
    dispatch(setSearchText(""));
    dispatch(setProfileStatus(null));
    dispatch(setStartDate(moment().add(-7, "d")));
    dispatch(setEndDate(moment()));
    submitFilter()
  };

  const submitFilter = () => {
    dispatch(setCurrentPage(1));
    handleRouteChange(1);
    dispatch(getCustomers());
  };

  const Filter = () => {
    return (
      <Row>
        <Col>
          <Row form>
            <Col md="4">
              <label htmlFor="" className="block">
                Search
              </label>
              <FormInput
                className=""
                placeholder="Search by name or email"
                value={state.filter.searchText}
                onChange={e => dispatch(setSearchText(e.target.value))}
              />
            </Col>
            <Col md="3">
              <label htmlFor="" className="block">
                Filter by status
              </label>
              <FormSelect className="" onChange={e => dispatch(setProfileStatus(e.target.value))} value={state.filter.profileStatus}>
                <option>Filter by status..</option>
                <option value="0">All</option>
                <option value="1">Active</option>
                <option value="2">Blocked</option>
                <option value="3">Deleted</option>
                <option value="4">Admin Deleted</option>
              </FormSelect>
            </Col>

            <Col md="2">
              <div>
                <label htmlFor="" className="block">
                  From
                </label>
                <SingleDatePicker
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  date={state.filter.startDate} // momentPropTypes.momentObj or null,
                  id="filterStartDate" // PropTypes.string.isRequired,
                  onDateChange={date => {
                    dispatch(setStartDate(date));
                  }} // PropTypes.func.isRequired,
                  focused={focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={({ focused }) => setFocusedInput1(focused)} // PropTypes.func.isRequired,
                />
              </div>
            </Col>

            <Col md="2">
              <div>
                <label htmlFor="" className="block">
                  To
                </label>
                <SingleDatePicker
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  date={state.filter.endDate} // momentPropTypes.momentObj or null,
                  id="filterEndDate" // PropTypes.string.isRequired,
                  onDateChange={date => {
                    dispatch(setEndDate(date));
                  }} // PropTypes.func.isRequired,
                  focused={focusedInput2} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={({ focused }) => setFocusedInput2(focused)} // PropTypes.func.isRequired,
                />
              </div>
            </Col>

            <Col md="1" className="d-flex flex-column  justify-content-around">
              <span className="cursor-pointer text-primary" onClick={resetFilters}>
                Clear filter
              </span>
              <Button onClick={submitFilter}>Filter</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const Summary = () => {
    return (
      <div className="summary-container">
        {state.summary.map((summary, index) => {
          return <SummaryCard title={summary.key} count={summary.count} key={index} />;
        })}
        {/* <SummaryCard title="total" value={getTotal()} /> */}
      </div>
    );
  };

  const CustomersListView = () => {
    const customers = state.customers;
    const rowNumber = index => {
      return state.filter.pageSize * state.filter.currentPage - state.filter.pageSize + (index + 1);
    };

    return (
      <React.Fragment>
        <ResponsiveTable
          headerData={["", "First Name", "Last Name", "Email", "Username", "Registered On", "Status", "Active Sims", "Wallet Balance", ""]}
        >
          {customers.map((customer, index) => {
            return (
              <tr
                key={customer.id}
                className="cursor-pointer rounded bg-white hover:bg-blue-200"
                style={{ boxShadow: "0 2px 14px 1px rgba(0, 0, 0, 0.03)" }}
              >
                <td className="text-gray-600 font-normal text-left text-base px-4 py-3">{rowNumber(index)}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{customer.firstName}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{customer.lastName}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{customer.email}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{customer.userName}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{toFriendlyDate(customer.registrationDate)}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{customer.profileStatusDescription}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">{customer.activeSims}</td>
                <td className="text-gray-600 font-normal text-left text-base px-2 py-3">
                  {toThousandCurrencyFormat(customer.walletBalance)}
                </td>
                <td>
                  <Button
                    className="uppercase text-sm"
                    outline
                    onClick={() => history.push(`/customer/${customer.id}`)}
                  >
                    Details
                  </Button>
                </td>
              </tr>
            );
          })}
        </ResponsiveTable>
        <Pagination
          pageSize={parseInt(state.filter.pageSize)}
          currentPage={parseInt(state.filter.currentPage)}
          totalRecords={state.filter.totalRecords}
          onRouteChange={handleRouteChange}
        />
      </React.Fragment>
    );
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Users" subtitle="Customer" className="text-sm-left" />
      </Row>
      <div className="my-6">
        <Summary />
      </div>
      <Row>
        <Col>
          {FilterCard(Filter())}
          {renderView()}
        </Col>
      </Row>
    </Container>
  );
};

export default Customers;
