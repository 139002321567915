import moment from 'moment'
import React from "react";
import { useLocation } from "react-router-dom";


const lowercaseFirstLetter = str => {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

const toInitials = str => {
  return str.split(" ").map(e => e.charAt(0).toUpperCase()).join("");
}

const spaceBeforeCap = str => str.replace(/([a-z])([A-Z])/g, '$1 $2')

const toThousandCurrencyFormat = (value) => {
    value = parseFloat(value);
    if (value && value !== '0')
        return "₦" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    else
        return "₦0.00";
}

const toThousandFormat = (value) => {
    if(value === undefined) return 0;

    value = parseFloat(value);
    if (value && value !== '0')
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    else
        return 0;
}

const toStartOfDay = (date) => {
  return moment(date).startOf('day').format();
}

const toEndOfDay = (date) => {
  return moment(date).endOf('day').format();
}

const toFriendlyDate = (date) => {
    let utcDate = moment.utc(date);
    return utcDate.local().format('ddd Do MMM \'YY HH:mm:ss');
}

const toTickOrCross = (boolVal) => {
  return boolVal ? '✅' : '❌';
}

const buildUrlFromQueryObject = (baseurl, queryObject) => {
    Object.keys(queryObject).forEach(key => {
        if (queryObject[key]) baseurl = baseurl + `${key}=${encodeURIComponent(queryObject[key])}&`;
    })
    baseurl = baseurl.substring(0, baseurl.lastIndexOf('&'));
    return baseurl;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export {
  toThousandCurrencyFormat,
  toFriendlyDate,
  toThousandFormat,
  buildUrlFromQueryObject,
  useQuery,
  lowercaseFirstLetter,
  spaceBeforeCap,
  toInitials,
  toStartOfDay,
  toEndOfDay,
  toTickOrCross
};
