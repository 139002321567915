import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, FormSelect } from "shards-react";
import { connect } from "react-redux";
import Chart from "../../utils/chart";
import { handleProvidersConversionRate, handleProvidersConversionRateByNetwork, setOtpEndDate, setOtpNework, setOtpProvider, setOtpStartDate } from "../../redux/otpConversionRates";
import { colors } from "../blog/chartColors";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

class OtpConversionChart extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();

    this.state = {
      startDate: {
        focus: false
      },
      endDate: {
        focus: false
      }
    };
  }

  componentDidMount() {
    this.props.dispatch(handleProvidersConversionRate());

    const OtpChart = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: this.props.chartData,
      // options: chartOptions
    });

    if (this.props.chartData) {
      const buoMeta = OtpChart.getDatasetMeta(0);
      buoMeta.data[0]._model.radius = 0;
      buoMeta.data[this.props.chartData.datasets[0].data.length - 1]._model.radius = 0;

      // Render the chart.
      OtpChart.render();
    }
  }

  componentDidUpdate() {
    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                reverse: true,
                callback(tick, index) {
                  // Jump every 7 values on the X axis labels to avoid clutter.
                  return tick;
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 100,
                suggestedMin: 0,
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return tick;
                }
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...this.props.chartOptions
    };
    const { otpProvidersConversionRate } = this.props.otpProvidersConversionRate;
    let { labels, datasets } = otpProvidersConversionRate;

    datasets = datasets.map((data, i) => ({ ...data, ...colors[i] }));
    const OtpChart = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: { labels, datasets },
      options: chartOptions
    });

    //They can still be triggered on hover.
    if (this.props.chartData) {
      const buoMeta = OtpChart.getDatasetMeta(0);
      buoMeta.data[0]._model.radius = 0;
      buoMeta.data[this.props.chartData.datasets[0].data.length - 1]._model.radius = 0;

      // Render the chart.
      OtpChart.render();
    }
  }

  render() {
    const { title, dispatch } = this.props;
    const { startDate, endDate } = this.props.otpProvidersConversionRate;
    console.log({startDate})
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <Col sm="4" className="d-flex mb-2 mb-sm-0">
              <SingleDatePicker
                numberOfMonths={1}
                isOutsideRange={() => false}
                date={moment(startDate)}
                id="filterStartDate"
                onDateChange={date => {
                  dispatch(setOtpStartDate(moment(new Date(date._d)).startOf('day').format()))
                  dispatch(handleProvidersConversionRate())
                }}
                focused={this.state.startDate.focus}
               onFocusChange={({ focused }) => this.setState({ startDate: { focus: focused } })}
              />
              <SingleDatePicker
                numberOfMonths={1}
                isOutsideRange={() => false}
                date={moment(endDate)}
                id="filterEndDate"
                onDateChange={date => {
                  dispatch(setOtpEndDate(moment(new Date(date._d)).format('MM/DD/YYYY')))
                  dispatch(handleProvidersConversionRate())
                }}
                focused={this.state.endDate.focus}
               onFocusChange={({ focused }) => this.setState({ endDate: { focus: focused } })}
              />
            </Col>
            <Col sm="2">
              <FormSelect size="sm" style={{ maxWidth: "130px" }} onChange={(e) => {
                dispatch(setOtpProvider(e.target.value))
                dispatch(handleProvidersConversionRate())
              }}>
                <option value="">All Providers</option>
                <option value="7">Coure</option>
                <option value="8">DigitalPulseSMS</option>
                <option value="9">Nexmo</option>
                <option value="10">Ejoin SMS</option>
                <option value="14">Termii SMS</option>
                <option value="15">Termii WhatsApp</option>
                <option value="17">OpenVox</option>
              </FormSelect>
            </Col>
            <Col sm="2">
              <FormSelect size="sm" style={{ maxWidth: "130px" }} onChange={(e) => {
                 dispatch(setOtpNework(e.target.value))
                 dispatch(handleProvidersConversionRateByNetwork())
              }}>
                <option value="">Networks</option>
                <option value="mtn">MTN</option>
                <option value="airtel">AIRTEL</option>
                <option value="glo">GLO</option>
                <option value="9mobile">9MOBILE</option>
              </FormSelect>
            </Col>
            {/* <Col sm="3">
              <OtpButtonGroups />
            </Col> */}
          </Row>
          <canvas height="130" ref={this.canvasRef} style={{ maxWidth: "100% !important" }} />
        </CardBody>
      </Card>
    );
  }
}

OtpConversionChart.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

const mapPropsToState = ({ otpProvidersConversionRate }) => {
  return {
    otpProvidersConversionRate
  };
};
export default connect(mapPropsToState)(OtpConversionChart);
