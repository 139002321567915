import React from 'react'
const ActiveStatus = ({ status }) => {
  console.log(status)
  if (!status) {
    return (
      <span className="px-2 inline-flex text-sm  leading-5 font-semibold rounded-full bg-red-100 text-red-800">
         InActive
      </span>
    );
  }

  return (
    <span className="px-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      Active
    </span>
  );
};


export default ActiveStatus;