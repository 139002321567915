import React, {useEffect, useRef} from "react";
import {useParams} from "react-router-dom";

import {
  getCustomerSims,
  getCustomerTransactions,
  getCustomer,
  deleteCustomer,
  blockCustomer
} from "../../redux/customers";
import {useSelector, useDispatch} from "react-redux";
import {
  toFriendlyDate,
  toInitials,
  toThousandCurrencyFormat, toTickOrCross
} from "../../utils/helpers";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../assets/react_dates_overrides.css";
import {Button, Card, CardHeader, Col, Container, Row} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import ResponsiveTable from "../../components/custom/ResponsiveTable";
import {getSims, setCurrentPage} from "../../redux/sims";
import ConfirmationDialog from "../../components/custom/ConfirmationDialog";
import {sendMessage} from "../../redux/messages";

const CustomerProfile = () => {
  const state = useSelector(state => state.customers);
  const dispatch = useDispatch();
  const param = useParams();
  const dialogRef = useRef();


  useEffect(() => {
    dispatch(getCustomer(param.id));
    dispatch(getCustomerSims(param.id));
    dispatch(getCustomerTransactions(param.id));
  }, []);

//   const renderView = () => {
//     if (state.viewState === viewStates.ERROR) {
//       return <p>Error occurred</p>;
//     } else if (state.viewState === viewStates.RETRIEVED) {
//       return <CustomersListView />;
//     }
//   };

  const SimsTransactionsListView = () => {
    const transactions = state.transactions;

    return (
      <ResponsiveTable
        title="Transactions"
        headerData={["Amount", "Reference", "Type", "Status", "Date"]}
      >
        {transactions.map(transaction => {
          return (
            <tr key={transaction.id}>
              <td>
                NGN {toThousandCurrencyFormat(transaction.amount)}
              </td>
              <td>
                {transaction.transactionReference || "-"}
              </td>
              <td>
                {transaction.transactionTypeDescription}
              </td>
              <td>
                {transaction.transactionStatusDescription}
              </td>
              <td>
                {toFriendlyDate(transaction.initiatedOn)}
              </td>
            </tr>
          );
        })}
      </ResponsiveTable>
    );
  };

  const KYCStatus = () => {
    var customer = state.customer;
    return (
      <Card small className="mb-4 pt-1">
        <CardHeader className="border-bottom">
          <h4 className="text-lg mb-0">KYC Level {KYCLevel(customer.kycLevel)}</h4>
        </CardHeader>
        <div
          className="text-center my-3 flex items-center justify-between">
          <div className="w-1/6">
            <p className="uppercase text-gray-500 tracking-wide">
              Email
            </p>
            <p>
              {toTickOrCross(customer.isEmailVerified)}
            </p>
          </div>
          <div className="w-1/6">
            <p className="uppercase text-gray-500 tracking-wide">
              NIN
            </p>
            <p>
              {toTickOrCross(customer.isNinVerified)}
            </p>
          </div>
          <div className="w-1/6">
            <p className="uppercase text-gray-500 tracking-wide">
              Selfie
            </p>
            <p>
              {toTickOrCross(customer.isSelfieVerified)}
            </p>
          </div>
          <div className="w-1/6">
            <p className="uppercase text-gray-500 tracking-wide">
              Docs
            </p>
            <p>
              {toTickOrCross(customer.isDocumentsVerified)}
            </p>
          </div>
          <div className="w-1/6">
            <p className="uppercase text-gray-500 tracking-wide">
              Identity
            </p>
            <p>
              {toTickOrCross(customer.isPersonalDetailsVerified)}
            </p>
          </div>
          <div className="w-1/6">
            <p className="uppercase text-gray-500 tracking-wide">
              BVN
            </p>
            <p>
              {toTickOrCross(customer.isBvnVerified)}
            </p>
          </div>
        </div>
      </Card>
    );
  };

    const SimsListView = () => {
    const sims = state.sims;

    return (
      <>
        <ResponsiveTable
          title="Sims"
          headerData={["Code", "Phone Number", "Network", "Serial Number", "Status", "Added on"]}
        >
          {sims.map(sim => {
            return (
              <tr key={sim.id}>
                <td>
                  {sim.simCode}
                </td>
                <td>
                  {sim.phoneNumber}
                </td>
                <td>
                  {sim.network}
                </td>
                <td>
                  {sim.serialNumber}
                </td>
                <td>
                  {sim.simStatusDescription}
                </td>
                <td>
                  {toFriendlyDate(sim.createdOn)}
                </td>
              </tr>
            );
          })}
        </ResponsiveTable>
      </>
    );
  };

  const DeleteButton = () => {
    return (
      <Button
        className="ml-3"
        theme="danger"
        onClick={() => {
          dialogRef.current.open(
            "Delete " + (state.customer.userName || "this Customer"),
            "Are you sure you want to?",
            false,
            "Yes, Delete"
          ).then(doIt => {
            if (doIt) {
              dispatch(deleteCustomer(state.customer.id))
            }
          });
        }}>
        Delete
      </Button>
    );
  };

  const BlockButton = () => {
    return (
      <Button
        className="ml-3"
        theme="warning"
        onClick={() => {
          dialogRef.current.open(
            "Block " + (state.customer.userName || "this Customer"),
            "What's the reason for blocking this customer?",
            true,
            "Yes, Block",
          ).then(([doIt, message]) => {
            if (doIt) {
              dispatch(blockCustomer(state.customer.id, message))
            }
          });
        }}>
        Block Customer
      </Button>
    );
  };

  const KYCLevel = (status) => {
    let style="";
    switch (status) {
      case 1:
        style={color:"#f8ecd0", backgroundColor: "rgb(162,124,24)"};
        break;
      case 2:
        style={color:"#969a9a", backgroundColor: "rgb(227,227,227)"};
        break;
      case 3:
        style={color:"#8a803c", backgroundColor: "rgb(227,204,91)"};
        break;
      default:
        style={color:"#1ac086", backgroundColor: "rgba(26, 192, 134, 0.1)"};
        break;
    }
    return (
        <span className="mx-1 w-8 py-0 rounded-full inline-block text-center text base"
            style={style}>
          {status}
        </span>
    );
  }

  const UserStatus = (status, reason, date, admin) => {
    let style="";
    let isBlocked = status === "Blocked" && reason !== null;
    if (status === "Active") {
      style={color:"#1ac086", backgroundColor: "rgba(26, 192, 134, 0.1)"};
    } else {
      style={color:"rgb(202, 73, 69)", backgroundColor: "rgba(202, 73, 69, 0.14)"};
    }
    return (
      <div>
      <span className="px-4 py-1 rounded-full inline-block mt-2 font-normal text-base"
        style={style}>
          {status}
        </span>
        {isBlocked && <div className="px-4 py-1 mt-2 font-normal text-base">
          by {admin} on {toFriendlyDate(date)}<br/><span className="italic">{reason}</span>
        </div>}
      </div>
    );
  }

  const ProfileCard = () => {
    const customer = state.customer;

    return (
      <Card small className="mb-4 pt-3">
        <CardHeader className="border-bottom text-center">
          <div
            className="w-32 h-32 mb-3 mx-auto bg-gray-600 rounded-full border text-white text-3xl p-8">
            {/*first or last name may contain spaces*/}
            {toInitials(customer.firstName + " " + customer.lastName)}
          </div>
          <h4 className="text-lg mb-0">{customer.firstName} {customer.lastName}</h4>
          <span className="text-muted d-block mb-2">{customer.email}</span>
          <span className="text-muted d-block mb-2">{customer.userName}</span>
          {UserStatus(customer.profileStatusDescription, customer.lastStatusUpdateReason, customer.lastStatusUpdateDate, customer.lastStatusUpdateBy)}
        </CardHeader>
        <div
          className="w-3/4 text-center my-6 flex items-center justify-between mx-auto">
          <div>
            <p className="uppercase text-gray-500 text-base tracking-wide">
              Registered on
            </p>
            <p className="text-base font-semibold text-gray-600">
              {toFriendlyDate(customer.registrationDate)}
            </p>
          </div>
          <div className="uppercase text-gray-500 tracking-wide">
            <p className="uppercase text-gray-500 tracking-wide">
              Active Sims
            </p>
            <p className="text-base font-semibold text-gray-600">
              {customer.activeSims}
            </p>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <Col>
          <PageTitle sm="4" title="Profile" subtitle="Customer"
                     className="text-sm-left" showBackArrow/>
        </Col>
        <Col className="text-right">
          <BlockButton/>
          <DeleteButton/>
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <ProfileCard/>
        </Col>
        <Col lg="8">
          <SimsListView/>
          <KYCStatus/>
        </Col>
      </Row>
      <Row>
        <Col>
          <SimsTransactionsListView/>
        </Col>
      </Row>
      <ConfirmationDialog ref={dialogRef}/>
    </Container>
  );
};

export default CustomerProfile;
