import { viewStates, setViewState } from "../utils/constants";
import { http, getToken, http_admin } from "../utils/axios-module";
import moment from "moment";
import {buildUrlFromQueryObject, toEndOfDay, toStartOfDay} from "../utils/helpers";
import { ToastContainer, toast, Slide } from "react-toastify";

const initialState = {
  viewState: viewStates.IDLE,
  users: [],
  totalRecords: 0,
  summary: [],
  user: {},
  filter: {
    startDate: moment().add(-7, "d"),
    endDate: moment(),
    searchText: "",
    profileStatus: "",
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10
  }
};

export function getUsers() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    let filter = {
      searchText: state.backofficeUsers.filter.searchText,
      startDate: toStartOfDay(state.backofficeUsers.filter.startDate),
      endDate: toEndOfDay(state.backofficeUsers.filter.endDate),
      currentPage: parseInt(state.backofficeUsers.filter.currentPage),
      pageSize: parseInt(state.backofficeUsers.filter.pageSize),
      profileStatus: parseInt(state.backofficeUsers.filter.profileStatus)
    };
    let url = buildUrlFromQueryObject("", filter);
    dispatch(setViewState(viewStates.BUSY));
    let base = process.env.REACT_APP_CLIENT_API;
    console.log(`making request to /users?${url}`);
    http_admin
      .get(`/users?${url}`)
      .then(response => {
        dispatch(setUsers(response.data.data.result));
        dispatch(setTotalRecords(response.data.data.totalRecords));
        dispatch(setViewState(viewStates.RETRIEVED));
        console.table(response.data.data.result)
      })
      .catch(error => {
        ;
      });
  };
}

// export function getCustomer(customerId) {
//   //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
//   return (dispatch, getState) => {
//     const state = getState();
//     dispatch(setViewState(viewStates.BUSY));
//     let base = process.env.REACT_APP_CLIENT_API;
//     http
//       .get(`/users/${customerId}`)
//       .then(response => {
//         dispatch(setUser(response.data.data));
//         dispatch(setViewState(viewStates.RETRIEVED));
//         console.table(response.data.data);
//       })
//       .catch(error => {
//         ;
//       });
//   };
// }

// export function getCustomerSims(customerId) {
//   //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
//   return (dispatch, getState) => {
//     const state = getState();
//     dispatch(setViewState(viewStates.BUSY));
//     let base = process.env.REACT_APP_CLIENT_API;
//     http
//       .get(`/users/${customerId}/sims`)
//       .then(response => {
//         dispatch(setUserSims(response.data.data));
//         dispatch(setViewState(viewStates.RETRIEVED));
//         console.table(response.data.data);
//       })
//       .catch(error => {
//         ;
//       });
//   };
// }

// export function getCustomerTransactions(customerId) {
//   //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
//   return (dispatch, getState) => {
//     const state = getState();
//     dispatch(setViewState(viewStates.BUSY));
//     let base = process.env.REACT_APP_CLIENT_API;
//     http
//       .get(`/users/${customerId}/transactions`)
//       .then(response => {
//         dispatch(setUserTransactions(response.data.data));
//         dispatch(setViewState(viewStates.RETRIEVED));
//         console.table(response.data.data);
//       })
//       .catch(error => {
//         ;
//       });
//   };
// }

// export function getDashboardData() {
//   //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
//   return (dispatch, getState) => {
//     dispatch(setViewState(viewStates.BUSY));
//     http
//       .get(`/users/dashboarddata`)
//       .then(response => {
//         dispatch(setDashboardData(response.data.data));
//         dispatch(setViewState(viewStates.RETRIEVED));
//         console.table(response.data.data);
//       })
//       .catch(error => {
//         ;
//       });
//   };
// }

export function getUsersSummary() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/users/summary`)
      .then(response => {
        dispatch(setSummary(response.data.data));
      })
      .catch(error => {
        ;
      });
  };
}

export function createUser(payload) {
  //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .post(`/users`, payload)
        .then(response => {
            toast.success(response.data.message || "User created successfully.");
            dispatch(setViewState(viewStates.RETRIEVED));
            resolve(true)
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "Error creating users."
          );
          dispatch(setViewState(viewStates.IDLE));
                      resolve(false);

        });
      })
    };
}

export function blockUser(userid) {
  //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .patch(`/users/${userid}/block`)
        .then(response => {
            toast.success(response.data.message || "User blocked successfully.");
            dispatch(setViewState(viewStates.RETRIEVED));
            resolve(true)
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "User creation failed."
          );
          dispatch(setViewState(viewStates.IDLE));
          resolve(false);

        });
      })
    };
}

export function unblockUser(userid) {
  //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .patch(`/users/${userid}/unblock`)
        .then(response => {
            toast.success(response.data.message || "User unblocked successfully.");
            dispatch(setViewState(viewStates.RETRIEVED));
            resolve(true)
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "User unblock failed."
          );
          dispatch(setViewState(viewStates.IDLE));
          resolve(false);

        });
      })
    };
}

export function deleteUser(userid) {
  //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .delete(`/users/${userid}`)
        .then(response => {
            toast.success(response.data.message || "User deleted successfully.");
            dispatch(setViewState(viewStates.RETRIEVED));
            resolve(true)
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "User deletion failed."
          );
          dispatch(setViewState(viewStates.IDLE));
          resolve(false);

        });
      })
    };
}

export function editUser(userid, payload) {
  //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .put(`/users/${userid}`,  payload)
        .then(response => {
            toast.success(response.data.message || "User updated successfully.");
            dispatch(setViewState(viewStates.RETRIEVED));
            resolve(true)
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "User update failed."
          );
          dispatch(setViewState(viewStates.IDLE));
          resolve(false);

        });
      })
    };
}

export function changeUserPassword(userid, payload) {
  // http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .post(`/users/${userid}/changeuserpassword`,  payload)
        .then(response => {
            toast.success(response.data.message || "User password updated successfully.");
            dispatch(setViewState(viewStates.RETRIEVED));
            resolve(true)
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "Password update failed."
          );
          ;
          resolve(false);

        });
      })
    };
}

export function getUserProfile(userid) {
  // http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin.get(`/users/${userid}`)
        .then(response => {
            dispatch(setViewState(viewStates.RETRIEVED));
            dispatch(setUserProfile(response.data.data))
            resolve(true)
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "Password update failed."
          );
          ;
              resolve(false)

        });
    })
}
}




export function setUserProfile(user) {
  return {
    type: "SET_USER_PROFILE",
    payload: user
  };
}

export function setSummary(summary) {
  return {
    type: "SET_SUMMARY",
    payload: summary
  };
}
export function setStartDate(startDate) {
  return {
    type: "SET_START_DATE",
    payload: startDate
  };
}

export function setEndDate(endDate) {
  return {
    type: "SET_END_DATE",
    payload: endDate
  };
}

export function setCurrentPage(currentPage) {
  return {
    type: "SET_CURRENT_PAGE",
    payload: currentPage
  };
}

export function setSearchText(searchText) {
  return {
    type: "SET_SEARCH_TEXT",
    payload: searchText
  };
}

export function setProfileStatus(profileStatus) {
  return {
    type: "SET_PROFILE_STATUS",
    payload: profileStatus
  };
}

export function setTotalRecords(totalRecords) {
  return {
    type: "SET_TOTAL_RECORDS",
    payload: totalRecords
  };
}

function setUsers(users) {
  return {
    type: "SET_USERS",
    payload: users
  };
}

export default function usersReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_USER_PROFILE":
      return {
        ...state,
        user: action.payload
      };
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        filter: { ...state.filter, currentPage: action.payload }
      };
    case "SET_TOTAL_RECORDS":
      return {
        ...state,
        filter: { ...state.filter, totalRecords: action.payload }
      };
    case "SET_PROFILE_STATUS":
      return {
        ...state,
        filter: { ...state.filter, profileStatus: action.payload }
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        filter: { ...state.filter, searchText: action.payload }
      };
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_USERS":
      return {
        ...state,
        users: action.payload
      };
    case "SET_START_DATE":
      return {
        ...state,
        filter: { ...state.filter, startDate: moment(action.payload) }
      };
    case "SET_END_DATE":
      return {
        ...state,
        filter: { ...state.filter, endDate: moment(action.payload) }
      };

    default:
      return state;
  }
}
