import React from "react";
import {
  Card, CardBody,
  Col,
  Row
} from "shards-react";

const FilterCard = (filter) => {
  return (
    <Card small className="mb-4">
      <CardBody className="bg-light">
          <Row>
            <Col>
              {filter}
            </Col>
          </Row>
      </CardBody>
    </Card>
  );
};

export default FilterCard;
