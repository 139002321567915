import React from "react";
import { Modal } from "react-responsive-modal";
import UpdateGatewayPriorities from "./UpdateGatewayPriorities";
// import {Modal} from "shards-react";

const GatewayModal = ({ details, open, close }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      center
      styles={{
        modal: { background: "transparent" },
        closeButton: { padding: "1rem" }
      }}
    >
      {details && <UpdateGatewayPriorities detials={details}  />}
    </Modal>
  );
};

export default GatewayModal;
