import React from 'react';
import {Col, Row} from "shards-react";
import {toFriendlyDate, toThousandCurrencyFormat} from "../../utils/helpers";

const TransactionHistory = ({history}) => {
  if (history === null)
    return "";
  if (history.length === 0)
    return (
      <Row>
      <Col>
        <p className="py-3 uppercase font-semibold">NO Transaction History Available</p>
      </Col>
    </Row>
    );

  history.sort((a,b) => {
    if(a.entryDate < b.entryDate) { return -1; }
    if(a.entryDate > b.entryDate) { return 1; }
    return 0;
  });

  return (
    <React.Fragment>
      <Row>
        <Col>
          <p className="py-3 uppercase font-semibold">Transaction History</p>
        </Col>
      </Row>
      {history.map((log, index) => (
        <div key={index}>
          <Row className="mb-1">
            <Col className="text-right">
              {toFriendlyDate(log.entryDate)}
            </Col>
          </Row>
          <div className="bg-gray-200 p-2 mb-2 rounded-sm text-base leading-relaxed">
            <Row className="mb-1">
              <Col sm="1">
                {log.transactionSuccess ? "✅" : "❌"}
              </Col>
              <Col sm="8">
                {log.from} => {log.to}
              </Col>
              <Col sm="3" className="text-right">
                {toThousandCurrencyFormat(log.moneyMovement * log.amount)}
              </Col>
            </Row>
            <Row>
              <Col>
                {log.message}
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

export default TransactionHistory;
