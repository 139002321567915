import React from "react";
import PropTypes from "prop-types";
import store from "../redux/index";

import { Provider } from "react-redux";
import { ToastContainer, toast, Slide } from 'react-toastify';

const EmptyLayout = ({ children }) => (
  <Provider store={store}>
    <ToastContainer
      autoClose={5000}
      position={toast.POSITION.BOTTOM_CENTER}
      transition={Slide}
      hideProgressBar={true}
    />

    <div className="">
      {children}
      {/* {!noFooter && <MainFooter />} */}
    </div>
  </Provider>
);

EmptyLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

EmptyLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default EmptyLayout;
