import { viewStates, setViewState } from "../utils/constants";
import {getToken, http_admin} from "../utils/axios-module";
import moment from "moment";
import {buildUrlFromQueryObject, toEndOfDay, toStartOfDay} from "../utils/helpers";
// import { ToastContainer, toast, Slide } from "react-toastify";

const initialState = {
  viewState: viewStates.IDLE,
  customers: [],
  totalRecords: 0,
  summary: [],
  sims: [],
  transactions: [],
  customer: {},
  dashboard: {},
  filter: {
    startDate: moment().add(-7, "d"),
    endDate: moment(),
    searchText: "",
    profileStatus: 0,
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10,
  }
};

export function getCustomers() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    let filter = {
      searchText: state.customers.filter.searchText,
      startDate: toStartOfDay(state.customers.filter.startDate),
      endDate: toEndOfDay(state.customers.filter.endDate),
      currentPage: parseInt(state.customers.filter.currentPage),
      pageSize: parseInt(state.customers.filter.pageSize),
      profileStatus: parseInt(state.customers.filter.profileStatus)
    };
    let url = buildUrlFromQueryObject("", filter);
    dispatch(setViewState(viewStates.BUSY));
    console.log(`making request to /customers?${url}`);
    http_admin
      .get(`/customers?${url}`)
      .then(response => {
        dispatch(setCustomers(response.data.data.result));
        dispatch(setTotalRecords(response.data.data.totalRecords));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
        ;
      });
  };
}

export function getCustomer(customerId) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/customers/${customerId}`)
      .then(response => {

        dispatch(setCustomer(response.data.data));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
        ;
      });
  };
}

export function deleteCustomer(customerId) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .delete(`/customers/${customerId}`)
      .then(response => {
        dispatch(getCustomer(customerId));
      })
      .catch(error => {
      });
  };
}

export function blockCustomer(customerId, message) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setViewState(viewStates.BUSY));
    let payload = {
      reason: message,
    };
    http_admin
      .patch(`/customers/${customerId}/block`, payload)
      .then(response => {
        dispatch(getCustomer(customerId));
      })
      .catch(error => {
      });
  };
}

export function getCustomerSims(customerId) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/customers/${customerId}/sims`)
      .then(response => {
        dispatch(setCustomerSims(response.data.data));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
        ;
      });
  };
}

export function getCustomerTransactions(customerId) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/customers/${customerId}/transactions`)
      .then(response => {
        dispatch(setCustomerTransactions(response.data.data));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
        ;
      });
  };
}

export function getDashboardData() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    // dispatch(setViewState(viewStates.BUSY));
    let filter = {
      queryDate: moment().startOf('day').format()
    };
    let url = buildUrlFromQueryObject("", filter);
    http_admin
      .get(`/customers/dashboarddata?${url}`)
      .then(response => {
        dispatch(setDashboardData(response.data.data));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
      });
  };
}

export function getCustomersSummary() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/customers/customersummary`)
      .then(response => {
        dispatch(setSummary(response.data.data));
      })
      .catch(error => {
        ;
      });
  };
}

export function setDashboardData(data) {
  return {
    type: "SET_DASHBOARD_DATA",
    payload: data
  };
}

export function setCustomerTransactions(transactions) {
  return {
    type: "SET_CUSTOMER_TRANSACTIONS",
    payload: transactions
  };
}
export function setCustomerSims(sims) {
  return {
    type: "SET_CUSTOMER_SIMS",
    payload: sims
  };
}
export function setCustomer(customer) {
  return {
    type: "SET_CUSTOMER",
    payload: customer
  };
}

export function setSummary(summary) {
  return {
    type: "SET_SUMMARY",
    payload: summary
  };
}
export function setStartDate(startDate) {
  return {
    type: "SET_START_DATE",
    payload: startDate
  };
}

export function setEndDate(endDate) {
  return {
    type: "SET_END_DATE",
    payload: endDate
  };
}

export function setCurrentPage(currentPage) {
  return {
    type: "SET_CURRENT_PAGE",
    payload: currentPage
  };
}

export function setSearchText(searchText) {
  return {
    type: "SET_SEARCH_TEXT",
    payload: searchText
  };
}

export function setProfileStatus(profileStatus) {
  return {
    type: "SET_PROFILE_STATUS",
    payload: profileStatus
  };
}

export function setTotalRecords(totalRecords) {
  return {
    type: "SET_TOTAL_RECORDS",
    payload: totalRecords
  };
}

function setCustomers(customers) {
  return {
    type: "SET_CUSTOMERS",
    payload: customers
  };
}

export default function customersReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_DASHBOARD_DATA":
      return {
        ...state,
        dashboard: action.payload
      };
    case "SET_CUSTOMER_TRANSACTIONS":
      return {
        ...state,
        transactions: action.payload
      };
    case "SET_CUSTOMER_SIMS":
      return {
        ...state,
        sims: action.payload
      };
    case "SET_CUSTOMER":
      return {
        ...state,
        customer: action.payload
      };
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        filter: { ...state.filter, currentPage: action.payload }
      };
    case "SET_TOTAL_RECORDS":
      return {
        ...state,
        filter: { ...state.filter, totalRecords: action.payload }
      };
    case "SET_PROFILE_STATUS":
      return {
        ...state,
        filter: { ...state.filter, profileStatus: action.payload }
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        filter: { ...state.filter, searchText: action.payload }
      };
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_CUSTOMERS":
      return {
        ...state,
        customers: action.payload
      };
    case "SET_START_DATE":
      return {
        ...state,
        filter: { ...state.filter, startDate: moment(action.payload) }
      };
    case "SET_END_DATE":
      return {
        ...state,
        filter: { ...state.filter, endDate: moment(action.payload) }
      };

    default:
      return state;
  }
}
