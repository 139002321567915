import { viewStates, setViewState } from "../utils/constants";
import {getToken, http_admin} from "../utils/axios-module";

const initialState = {
  viewState: viewStates.IDLE,
  sendResult: null,
};

const setMessageResult = payload => ({
  type: "SET_MESSAGE_RESULT",
  payload
});

export function sendMessage(formData) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));

    http_admin
      .post(`/Notifications`, formData)
      .then(response => {
        dispatch(setMessageResult("Sent to " + response.data.data + " user(s)"));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
        dispatch(setMessageResult("Failed to send: " + error));
        dispatch(setViewState(viewStates.ERROR));
      });
  };
}

export default function messagesReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_MESSAGE_RESULT":
      return {
        ...state,
        sendResult: action.payload
      };
    default:
      return state;
  }
}
