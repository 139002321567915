import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { useHistory} from 'react-router-dom'
import {toInitials} from "../../../../utils/helpers";

const UserActions = (props) => {

  const [visible, setVisibility] = useState(false);
  const history = useHistory()

  const toggleUserActions = () =>  {
    setVisibility(!visible)
  }

  const userInitials = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if(user != null){
      return toInitials(user.fullName);
    }
    return "?"
  }

  const userDetail = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if(user != null){
      return user.fullName || user.userName
    }
    return "?"
  }

  const logout = () => {
    localStorage.removeItem('user');
    history.push('/login')
  }

    return (
      <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="px-3 flex items-center">
          <span
            className="w-10 h-10 bg-gray-600 rounded-full text-white flex items-center justify-center mr-2">
            {userInitials()}
          </span>
          <span className="d-none d-md-inline-block">{userDetail()}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} small open={visible}>
          {/* <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem>
          <DropdownItem divider /> */}
          <DropdownItem
            className=""
            onClick={() => history.push("/change-password")}
          >
            <i className="material-icons text-blue-primary">&#xE879;</i> Change
            Password
          </DropdownItem>
          <DropdownItem  className="text-danger" onClick={logout}>
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
}

export default UserActions
