import { viewStates, setViewState } from "../utils/constants";
import { http_admin, getToken } from "../utils/axios-module";
import {buildUrlFromQueryObject} from '../utils/helpers';

const initialState = {
  viewState: viewStates.IDLE,
  otps: [],
  totalRecords: 0,
  summary: [],
  filter: {
    gateway: "",
    verificationType: "",
    network: "",
    phoneNumber: "",
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10
  }
};


export function getOtps() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    let filter = {
      gateway: state.otps.filter.gateway,
      verificationType: state.otps.filter.verificationType,
      network: state.otps.filter.network,
      phoneNumber: state.otps.filter.phoneNumber,
      currentPage: parseInt(state.otps.filter.currentPage),
      pageSize: parseInt(state.otps.filter.pageSize),
    };
    let url = buildUrlFromQueryObject("", filter);
    dispatch(setViewState(viewStates.BUSY));
    console.log(`making request to /otps?${process.env.REACT_APP_CLIENT_API}`);
    http_admin
      .get(`/otps?${url}`)
      .then(response => {
        dispatch(setOtps(response.data.data.result));
        dispatch(setTotalRecords(response.data.data.totalRecords));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
        ;
      });
  };
}

// export function getMessageSummary() {
//   http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
//   return (dispatch, getState) => {
//     dispatch(setViewState(viewStates.BUSY));
//     http
//       .get(`/otps/summary`)
//       .then(response => {
//         dispatch(setSummary(response.data.data));
//       })
//       .catch(error => {
//         ;
//       });
//   };
// }



export function setSummary(summary) {
  return {
    type: "SET_SUMMARY",
    payload: summary
  };
}

export function setCurrentPage(currentPage) {
  return {
    type: "SET_CURRENT_PAGE",
    payload: currentPage
  };
}

export function setTotalRecords(total) {
  return {
    type: "SET_TOTAL_RECORDS",
    payload: total
  };
}

export function setNetwork(network) {
  return {
    type: "SET_NETWORK",
    payload: network
  };
}

export function setGateway(gateway) {
  return {
    type: "SET_GATEWAY",
    payload: gateway
  };
}

export function setVerificationType(verificationType) {
  return {
    type: "SET_VERIFICATION_TYPE",
    payload: verificationType
  };
}

export function setPhoneNumber(phoneNumber) {
  return {
    type: "SET_PHONE_NUMBER",
    payload: phoneNumber
  };
}

function setOtps(otps) {
  return {
    type: "SET_OTPS",
    payload: otps
  };
}



export default function otpsReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        filter: { ...state.filter, currentPage: action.payload }
      };
    case "SET_TOTAL_RECORDS":
      return {
        ...state,
        filter: { ...state.filter, totalRecords: action.payload }
      };
    case "SET_NETWORK":
      return {
        ...state,
        filter: { ...state.filter, network: action.payload }
      };
    case "SET_GATEWAY":
      return {
        ...state,
        filter: { ...state.filter, gateway: action.payload }
      };
    case "SET_VERIFICATION_TYPE":
      return {
        ...state,
        filter: { ...state.filter, verificationType: action.payload }
      };
    case "SET_PHONE_NUMBER":
      return {
        ...state,
        filter: { ...state.filter, phoneNumber: action.payload }
      };
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_OTPS":
      return {
        ...state,
        otps: action.payload
      };

    default:
      return state;
  }
}
