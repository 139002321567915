import React from "react";
import {
  toThousandCurrencyFormat,
  toThousandFormat
} from "../../utils/helpers";
import {Card} from "shards-react";

const SummaryCard = props => {
  return (
    <Card className="summary">
      <p className="uppercase text-base tracking-wider text-center text-gray-500">
        {props.title}
      </p>
      {props.count != null ? (
        <h1 className="font-bold text-grey-600 text-2xl text-center">
          {toThousandFormat(props.count)}
        </h1>
      ) : null}
      {props.showSum ? (
        <div>
          <p className="uppercase text-base tracking-wider text-gray-500 text-center">
            Total Value
          </p>
          <h1 className="font-bold text-grey-600 text-xl text-center">
            {toThousandCurrencyFormat(props.sum)}
          </h1>
        </div>
      ) : null}
      {props.details != null ? (
        <div className="m-4">
          {props.details.map((value, index) => {
            return (
              <div key={index}>
                <p className="uppercase text-base text-gray-500 float-left clear-left">
                  {value.key}:
                </p>
                <p className="uppercase text-base text-gray-500 float-right clear-right">
                  {toThousandFormat(value.value)}
                </p>
              </div>
            );
          })}
        </div>
      ) : null}
    </Card>
  );
};

export default SummaryCard;
