export const viewStates = {
    IDLE: 0,
    BUSY: 1,
    ERROR: 2,
    RETRIEVED: 3
}

export const setViewState = (viewState) => {
    return {
      type: "SET_VIEW_STATE",
      payload: viewState
    };
}
