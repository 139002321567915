import { viewStates, setViewState } from "../utils/constants";
import { http, getToken } from "../utils/axios-module";
import moment from "moment";
import { buildUrlFromQueryObject } from "../utils/helpers";
import { ToastContainer, toast, Slide } from "react-toastify";

const initialState = {
  viewState: viewStates.IDLE,
  loadingState: "idle",
  products: [],
  product: {}
};


export function getProducts() {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;

  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http
      .get(`/products`)
      .then(response => {
        dispatch(setProducts(response.data.data));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
        toast.error(error.response.data.message || "Unable to fetch products.");
        dispatch(setViewState(viewStates.ERROR));

      });
  };
}

export function getProduct(productId) {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;

  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http
      .get(`/products/${productId}`)
      .then(response => {
        dispatch(setProduct(response.data.data));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
        toast.error(error.response.data.message || "Unable to fetch products.");
        dispatch(setViewState(viewStates.ERROR));

      });
  };
}

export function updateFees(product) {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http
        .post(`/products/commissions/update`, product)
        .then(response => {
            dispatch(setViewState(viewStates.RETRIEVED));
            resolve(true);
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "Error updating commissions"
          );
          dispatch(setViewState(viewStates.RETRIEVED));
          resolve(false);
        });
    })
  };
}


function setProducts(products) {
  return {
    type: "SET_PRODUCTS",
    payload: products
  };
}
function setProduct(product) {
  return {
    type: "SET_PRODUCT",
    payload: product
  };
}

export default function feesReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload
      };
    case "SET_PRODUCT":
      return {
        ...state,
        product: action.payload
      };
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };

    default:
      return state;
  }
}
