import React, {useEffect, useState, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ToastContainer, toast, Slide} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "../../components/components-overview/custom/Pagination";
import BusyOverlay from "../../components/layout/BusyOverlay";
import {
  getUsers,
  setCurrentPage,
  setStartDate,
  setEndDate,
  setSearchText,
  getUsersSummary,
  setProfileStatus,
  blockUser,
  unblockUser,
  editUser,
  deleteUser,
  changeUserPassword,
  getUserProfile
} from "../../redux/backofficeUsers";
import {useSelector, useDispatch} from "react-redux";
import {viewStates} from "../../utils/constants";
import {toFriendlyDate} from "../../utils/helpers";
import "react-dates/initialize";
import {SingleDatePicker} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "../../assets/react_dates_overrides.css";
import "react-responsive-modal/styles.css";
import {Modal} from "react-responsive-modal";
import SummaryCard from "../../components/custom/SummaryCard";
import UserForm from "../../components/custom/UserForm";
import EditUserForm from "../../components/custom/EditUserForm";
import {Button, Col, Container, FormInput, FormSelect, Row} from "shards-react";
import ConfirmationDialog from "../../components/custom/ConfirmationDialog";
import ChangeUserPasswordForm from "../../components/custom/ChangePasswordForm";
import PageTitle from "../../components/common/PageTitle";
import ResponsiveTable from "../../components/custom/ResponsiveTable";
import FilterCard from "../../components/custom/FilterCard";

const Users = () => {
  const state = useSelector(state => state.backofficeUsers);
  const dispatch = useDispatch();
  const history = useHistory();
  let page = state.filter.currentPage;
  const param = useParams();

  function handleRouteChange(pageNumber) {
    history.push(`/adminusers?page=${pageNumber}`);
    dispatch(setCurrentPage(pageNumber));
  }

  const [selectedUser, setSelectedUser] = useState({});
  const [focusedInput1, setFocusedInput1] = useState(false);
  const [focusedInput2, setFocusedInput2] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [listKey, setListKey] = useState(1);
  const [summaryKey, setSummaryKey] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(-1);
  const childRef = useRef();

  useEffect(() => {
    dispatch(getUsersSummary());
    dispatch(getUsers());
  }, [page, listKey]);

  const updateKeys = () => {
    setListKey(listKey + 1);
    setSummaryKey(summaryKey - 1);
  };

  const handleCleanUp = () => {
    setListKey(listKey + 1);
    setSummaryKey(summaryKey - 1);
    setEditModalOpen(false);
  };

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <UsersListView key={listKey}/>;
    }
  };

  const Filter = () => {
    return (
      <div className="w-full flex items-end">
        {/* {JSON.stringify(state.filter.profileStatus)} */}
        <div className="w-1/4">
          <label htmlFor="">Search text</label>
          <FormInput
            placeholder="Search by name/username/email"
            value={state.filter.searchText}
            onChange={e => dispatch(setSearchText(e.target.value))}
          />
        </div>
        <div className="w-1/4">
          <label htmlFor="">Status</label>
          <FormSelect
            onChange={e => dispatch(setProfileStatus(e.target.value))}
          >
            <option>Filter by status..</option>
            <option value="">All</option>
            <option value="1">Active</option>
            <option value="2">Blocked</option>
            <option value="3">Deleted</option>
          </FormSelect>
        </div>

        <div>
          <label htmlFor="" className="block">
            From
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.startDate} // momentPropTypes.momentObj or null,
            id="filterStartDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setStartDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({focused}) => setFocusedInput1(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <div>
          <label htmlFor="" className="block">
            To
          </label>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={() => false}
            date={state.filter.endDate} // momentPropTypes.momentObj or null,
            id="filterEndDate" // PropTypes.string.isRequired,
            onDateChange={date => {
              dispatch(setEndDate(date));
            }} // PropTypes.func.isRequired,
            focused={focusedInput2} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={({focused}) => setFocusedInput2(focused)} // PropTypes.func.isRequired,
          />
        </div>
        <button
          className="bg-blue-primary px-4 py-2 text-white border-none"
          onClick={() => {
            dispatch(setCurrentPage(1));
            handleRouteChange(1);
            dispatch(getUsers())
          }}
        >
          Filter
        </button>
      </div>
    );
  };

  const UsersListView = () => {
    const users = state.users;
    const rowNumber = index => {
      return (
        state.filter.pageSize * state.filter.currentPage -
        state.filter.pageSize +
        (index + 1)
      );
    };

    const renderDropdown = (index, user) => {
      const {id} = user;

      const openPasswordChangeModal = () => {
        setActiveIndex(-1);
        setChangePasswordModalOpen(true);
        setSelectedUser(user);
      };

      const openEditUserModal = async () => {
        setActiveIndex(-1);
        dispatch(getUserProfile(id)).then(response => {
          if (response) {
            setEditModalOpen(true);
          }
        });
      };

      const blockUserProfile = () => {
        setActiveIndex(-1);
        childRef.current
          .open("Block user", "Are you sure you want to block this user?")
          .then(result => {
            if (result) {
              dispatch(blockUser(id)).then(value => {
                if (value) {
                  setListKey(listKey + 1);
                  setSummaryKey(summaryKey + 1);
                }
              });
            }
          });
      };

      const unblockUserProfile = () => {
        setActiveIndex(-1);
        childRef.current
          .open("Unblock user", "Are you sure you want to unblock this user?")
          .then(result => {
            if (result) {
              dispatch(unblockUser(id)).then(value => {
                if (value) {
                  setListKey(listKey + 1);
                  setSummaryKey(summaryKey + 1);
                }
              });
            }
          });
      };

      const deleteUserProfile = () => {
        setActiveIndex(-1);
        childRef.current
          .open("Delete user", "Are you sure you want to delete this user?")
          .then(result => {
            if (result) {
              dispatch(deleteUser(id)).then(value => {
                if (value) {
                  setListKey(listKey + 1);
                  setSummaryKey(summaryKey + 1);
                }
              });
            }
          });
      };

      if (activeIndex === index) {
        return (
          <ul
            className="absolute border bg-white shadow-sm rounded z-50 w-48 left-0 cursor-pointer"
            style={{left: "-150px"}}
          >
            <li
              className="px-2 py-2 hover:bg-blue-200"
              onClick={blockUserProfile}
            >
              Block
            </li>
            <li
              className="px-2 py-2 hover:bg-blue-200"
              onClick={unblockUserProfile}
            >
              Unblock
            </li>
            <li
              className="px-2 py-2 hover:bg-blue-200"
              onClick={openEditUserModal}
            >
              Edit
            </li>
            {/* <li className="px-2 py-2 hover:bg-blue-200" onClick={openPasswordChangeModal}>Reset password</li> */}
            <li
              className="px-2 py-2 hover:bg-blue-200"
              onClick={deleteUserProfile}
            >
              Delete
            </li>
          </ul>
        );
      }

      return null;
    };

    const showDropdown = index => {
      if (index === activeIndex) {
        setActiveIndex(-1);
      } else {
        setActiveIndex(index);
      }
    };

    return (
      <React.Fragment>
        <ResponsiveTable
          headerData={["", "First name", "Last name", "Username", "Email", "Created on", "Status", ""]}
        >
          {users.map((user, index) => {
            return (
              <tr
                key={user.id}
              >
                <td>
                  {rowNumber(index)}
                </td>
                <td>
                  {user.firstName}
                </td>
                <td>
                  {user.lastName}
                </td>
                <td>
                  {user.userName}
                </td>
                <td>
                  {user.email}
                </td>
                <td>
                  {toFriendlyDate(user.createdOn)}
                </td>
                <td>
                  {user.profileStatusDescription}
                </td>
                <td className="relative">
                    <span
                      className="text-blue-primary cursor-pointer text-lg"
                      onClick={() => showDropdown(index)}
                    >
                      <i className="material-icons">settings</i>
                    </span>
                  {renderDropdown(index, user)}
                </td>
              </tr>
            );
          })}
        </ResponsiveTable>
        <Pagination
          pageSize={parseInt(state.filter.pageSize)}
          currentPage={parseInt(state.filter.currentPage)}
          totalRecords={state.filter.totalRecords}
          onRouteChange={handleRouteChange}
        />
      </React.Fragment>
    );
  };

  const Summary = () => {
    return (
      <div className="summary-container">
        {state.summary.map((summary, index) => {
          return (
            <SummaryCard
              title={summary.key}
              count={summary.count}
              key={index}
            />
          );
        })}
        {/* <SummaryCard title="total" value={getTotal()} /> */}
      </div>
    );
  };

  return (
    <>
      <ToastContainer
        autoClose={5000}
        position={toast.POSITION.BOTTOM_CENTER}
        transition={Slide}
        hideProgressBar={true}
      />

      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="Administrators" subtitle="Management"
                       className="text-sm-left"/>
          </Col>
          <Col className="text-right">
            <Button
              onClick={() => setUserModalOpen(true)}
            >
              Create new administrator
            </Button>
          </Col>
        </Row>
        <div className="my-6">
          <Summary key={summaryKey}/>
        </div>
        <Row>
          <Col>
            {FilterCard(Filter())}
            {renderView()}
          </Col>
        </Row>
        <div>
          <Modal
            open={userModalOpen}
            onClose={() => setUserModalOpen(false)}
            center
          >
            <UserForm
              updateKeys={updateKeys}
              listKey={listKey}
              summaryKey={summaryKey}
              closeModal={() => setUserModalOpen(false)}
            />
          </Modal>
          <Modal
            open={changePasswordModalOpen}
            onClose={() => setChangePasswordModalOpen(false)}
            center
          >
            <ChangeUserPasswordForm
              user={selectedUser}
              closeModal={() => setChangePasswordModalOpen(false)}
            />
          </Modal>
          <Modal
            open={editModalOpen}
            onClose={() => setEditModalOpen(false)}
            center
          >
            <EditUserForm cleanup={handleCleanUp}/>
          </Modal>
          <ConfirmationDialog ref={childRef}/>
        </div>
      </Container>

    </>
  );
};

export default Users;
