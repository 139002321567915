import {viewStates, setViewState} from "../utils/constants";
import {http, getToken} from "../utils/axios-module";

const initialState = {
  gateways: [],
  gatewaySims: [],
  gatewayModal: false,
  totalRecords: 0
};

const setGateways = payload => ({
  type: "SET_GATEWAYS",
  payload
});

const updateGatewayAction = payload => ({
  type: "UPDATE_GATEWAY",
  payload
});

export const gatewayModalAction = (payload) => ({
  type: "TOGGLE_GATEWAY_MODAL",
  payload
});

export const setTotalRecords = (payload) => ({
  type: "SET_TOTAL_RECORDS",
  payload
});

const gatewaySims = (payload) => ({
  type: "SET_GATEWAY_SIMS",
  payload
});


export function fetchGateways() {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http
      .get("/gateways")
      .then(response => {
        dispatch(setGateways(response.data.data));
        dispatch(setViewState(viewStates.RETRIEVED));
      })
      .catch(error => {
      });
  };
}

export function updateGateway(gateway) {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http
      .patch(`/gateways/${gateway.id}`, gateway)
      .then(response => {
        dispatch(updateGatewayAction(gateway));
        dispatch(setViewState(viewStates.RETRIEVED));
        dispatch(gatewayModalAction(false))
      })
      .catch(error => {
      });
  };
}

export function fetchGatewaySims(id, currentPage = 1, pageSize = 10) {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http
      .get(`/gateways/${id}/sims?CurrentPage=${currentPage}&PageSize=${pageSize}`)
      .then(response => {
        console.log(response.data.data.result);
        dispatch(gatewaySims(response.data.data.result))
        dispatch(setTotalRecords(response.data.data.totalRecords));
        dispatch(setViewState(viewStates.RETRIEVED));

      })
      .catch(error => {
      });
  };
}

export default function gatewaysReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_GATEWAYS":
      return {
        ...state,
        gateways: action.payload
      };
    case "UPDATE_GATEWAY":
      return {
        ...state,
        gateways: state.gateways.map(gateway => {
          if (gateway.id === action.payload.id) {
            return {...gateway, ...action.payload};
          }
          return gateway;
        })
      };
    case "TOGGLE_GATEWAY_MODAL":
      return {
        ...state,
        gatewayModal: action.payload
      };
    case "SET_GATEWAY_SIMS":
      return {
        ...state,
        gatewaySims: action.payload
      }
    case "SET_TOTAL_RECORDS":
      return {
        ...state,
        totalRecords: action.payload
      };
    default:
      return state;
  }
}
