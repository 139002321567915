export default function() {
  return [
    // {
    //   title: "Blog Dashboard",
    //   to: "/blog-overview",
    //   htmlBefore: '<i class="material-icons">edit</i>',
    //   htmlAfter: ""
    // },
    // {
    //   title: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts",
    // },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    {
      title: "Dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      to: "/dashboard",
    },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // },
    {
      title: "Users",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/customers",
    },
    {
      title: "Sims",
      htmlBefore: '<i class="material-icons">sim_card</i>',
      to: "/sims",
    },
    {
      title: "Transactions",
      htmlBefore: '<i class="material-icons">swap_vert</i>',
      to: "/simstransactions",
    },
    {
      title: "Support",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/supportissues",
    },
    {
      title: "Inward SMS",
      htmlBefore: '<i class="material-icons">sms</i>',
      to: "/inwardmessages",
    },
    {
      title: "OTPs",
      htmlBefore: '<i class="material-icons">verified_user</i>',
      to: "/otp-overview",
    },
    {
      title: "Activities",
      htmlBefore: '<i class="material-icons">touch_app</i>',
      to: "/activities",
    },
    {
      title: "Fees",
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/fees",
    },
    {
      title: "Gateways",
      htmlBefore: '<i class="material-icons">settings_input_antenna</i>',
      to: "/gateways",
    },
    {
      title: "Messages",
      htmlBefore: '<i class="material-icons">announcement</i>',
      to: "/messages",
    },
    {
      title: "Administrators",
      htmlBefore: '<i class="material-icons">supervisor_account</i>',
      to: "/adminusers",
    },

  ];
}
