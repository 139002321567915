import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Col, NavLink, Row} from "shards-react";
import {useHistory} from "react-router-dom";

const PageTitle = ({ title, subtitle, showBackArrow, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );
  const history = useHistory();

  return (
    <Col xs="12" sm="4" className={classes} { ...attrs }>
      {showBackArrow ?
          <NavLink href="#" onClick={history.goBack} className="p-0 pb-2 text-uppercase page-subtitle"><i className="material-icons">arrow_back</i> back</NavLink>
        : null
      }
      <span className="text-uppercase page-subtitle">{subtitle}</span>
      <h3 className="page-title">{title}</h3>
    </Col>
  )
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * The page subtitle.
   */
  showBackArrow: PropTypes.bool
};

export default PageTitle;
