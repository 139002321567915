import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Pagination from "../../components/components-overview/custom/Pagination";
import { fetchGatewaySims } from "../../redux/gateways";
import ResponsiveTable from "../../components/custom/ResponsiveTable";
import ActiveStatus from "../../components/custom/ActiveStatus";
import {Col, Container, NavItem, NavLink, Row} from "shards-react";
import PageTitle from "../../components/common/PageTitle";

const tableHeaders = [
  "Sim Number",
  "Sim Port",
  "IMSI",
  "Sim Balance",
  "Usage Count",
  "Sim Provider",
  "Gateway",
  "Daily Amount Limit",
  "Status",
  "Active"
];

const BodyData = ({ data }) => {
  return (
    <>
      {data.map(d => (
        <tr
          key={d.id}
          className="cursor-pointer rounded bg-white hover:bg-blue-200"
          style={{ boxShadow: "0 2px 14px 1px rgba(0, 0, 0, 0.03)" }}
        >
          <td>
            {d.simNumber}
          </td>
          <td>
            {d.simPort}
          </td>
          <td>
            {d.imsi}
          </td>
          <td>
            {d.simBalance}
          </td>
          <td>
            {d.usageCount}
          </td>
          <td>
            {d.simProvider}
          </td>
          <td>
            {d.gateway}
          </td>
          <td>
            {d.dailyAmountLimit}
          </td>
          <td>
            {d.status}
          </td>
          <td>
            <ActiveStatus status={d.isActive} />
          </td>
        </tr>
      ))}
    </>
  );
};

const GatewaySims = () => {
  const state = useSelector(state => state.gateways);
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    state: { name }
  } = useLocation();
  const gatewaySims = state.gatewaySims;
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchGatewaySims(id));
  }, [id]);

  const handleRouteChange = page => {
    setPage(page => page++);
    dispatch(fetchGatewaySims(id, page));
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4"
                   title={name.toUpperCase() + " Sims"}
                   subtitle="Management"
                   className="text-sm-left"
                   showBackArrow
        />
      </Row>
      <Row>
        <Col>
          <ResponsiveTable
            headerData={tableHeaders}>
            <BodyData data={gatewaySims} />
          </ResponsiveTable>
          <div className="mt-3">
            <Pagination
              pageSize={10}
              currentPage={page}
              totalRecords={state.totalRecords}
              onRouteChange={handleRouteChange}
            />
          </div>
        </Col>
      </Row>
    </Container>

  );
};

export default GatewaySims;
