import { viewStates, setViewState } from "../utils/constants";
import { http, getToken, http_admin } from "../utils/axios-module";
import moment from "moment";
import { buildUrlFromQueryObject } from "../utils/helpers";
import { ToastContainer, toast, Slide } from "react-toastify";

const initialState = {
  viewState: viewStates.IDLE,
  loadingState: "idle",
  fees: {}
};

export function setFees(payload) {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      http
        .post(`/products/setfees`, payload)
        .then(response => {
          resolve(true);
        })
        .catch(error => {
          toast.error(error.response.data.message || "Login failed.");
          resolve(false);
        });
    });
  };
}

export function getFees() {
  http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    http
      .get(`/products/getfees`)
      .then(response => {
        dispatch(setProductFees(response.data.data));
      })
      .catch(error => {
        toast.error(error.response.data.message || "Login failed.");
      });
  };
}


function setProductFees(fees) {
  return {
    type: "SET_PRODUCT_FEES",
    payload: fees
  };
}

export default function settingsReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_PRODUCT_FEES":
      return {
        ...state,
        fees: action.payload
      };

    default:
      return state;
  }
}
