import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FormInput } from 'shards-react';
import {changePassword } from '../../redux/auth';

const ChangePassword = props => {
  const dispatch = useDispatch();

  const MyInput = ({ field, form, ...props }) => {
    return <FormInput {...field} {...props} />;
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string().required("New password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    )
  });

  const handleSubmit = async(values, resetForm) => {
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();
    console.log(values)

    dispatch(changePassword(values)).then(response => {
        if(response){
            resetForm();
        }
    });
  };



  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  return (
      <div className="w-full flex items-center justify-center">
        <div className="bg-white w-1/3">
          <h2 className="text-xl font-semibold px-4 py-4 text-left text-black-500 border-b">
            Change password
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ values, errors, handleSubmit }) => (
              <form className="w-full px-8 py-6" onSubmit={handleSubmit}>
                {/* <p>{JSON.stringify(values)}</p> */}
                <div className="w-full">
                  <label htmlFor="">Current password</label>
                  <Field
                    name="currentPassword"
                    type="password"
                    component={MyInput}
                  />
                  {errors.currentPassword && (
                    <p className="text-red-500 text-sm">
                      {errors.currentPassword}
                    </p>
                  )}
                </div>
                <div className="w-full mt-5">
                  <label htmlFor="">Password</label>
                  <Field
                    name="newPassword"
                    type="password"
                    component={MyInput}
                  />
                  {errors.newPassword && (
                    <p className="text-red-500 text-sm">{errors.newPassword}</p>
                  )}
                </div>

                <div className="w-full mt-5">
                  <label htmlFor="">Confirm password</label>
                  <Field
                    name="confirmPassword"
                    type="password"
                    component={MyInput}
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-500 text-sm">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>

                <div className="flex items-center justify-start mt-12">
                  <button
                    type="button"
                    className="bg-blue-primary px-4 py-2 text-white border-none w-full"
                    onClick={() => handleSubmit(values)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
  );
};

export default ChangePassword;
