import { viewStates, setViewState } from "../utils/constants";
import { getToken, http_admin } from "../utils/axios-module";
import { toast } from "react-toastify";


const initialState = {
  viewState: viewStates.IDLE,
  loadingState: "idle"
};


export function login(payload) {
  //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .post(`/identity/login`, payload)
        .then(response => {
          console.log(response);
          dispatch(setViewState(viewStates.RETRIEVED));
          localStorage.setItem('user', JSON.stringify(response.data.data))
          resolve(true);
        })
        .catch(error => {
          var err = error.response === undefined? "Login failed.": error.response.data.message
          toast.error(
            err
          );
          dispatch(setViewState(viewStates.IDLE));
          resolve(false);
        });
    });
  };
}

export function initiatePasswordReset(payload) {
  //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .post(`/identity/initiatePasswordReset`, payload)
        .then(response => {
          dispatch(setViewState(viewStates.RETRIEVED));
          toast.success(response.data.message);

        })
        .catch(error => {
          toast.error(
            error.response.data.message || "Error occurred."
          );
          dispatch(setViewState(viewStates.IDLE));
        });
  };
}

export function completePasswordReset(payload) {
  //http.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .post(`/identity/completepasswordReset`, payload)
        .then(response => {
          dispatch(setViewState(viewStates.RETRIEVED));
          toast.success(response.data.message);
          resolve(true)
        })
        .catch(error => {
          toast.error(
            error.response.data.message || "Error occurred."
          );
          dispatch(setViewState(viewStates.IDLE));
          resolve(false)
        });
    })
  };
}

export function changePassword(payload) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      http_admin
        .post(`/identity/changepassword`, payload)
        .then(response => {
          toast.success(
            response.data.message || "Password updated successfully."
          );
          dispatch(setViewState(viewStates.RETRIEVED));
          resolve(true);
        })
        .catch(error => {
          toast.error(error.response.data.message || "Password update failed.");
          ;
          resolve(false);
        });
    });
  };
}



export function setLoadingState(newState) {

  return {
    type: "SET_LOADING_STATE",
    payload: newState
  };
}


export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_LOADING_STATE":
      return {
        ...state,
        loadingState: action.payload
      };

    default:
      return state;
  }
}
