import { viewStates, setViewState } from "../utils/constants";
import {http, getToken, http_admin} from "../utils/axios-module";
import moment from "moment";
import {buildUrlFromQueryObject, toEndOfDay, toStartOfDay} from '../utils/helpers';
// import { ToastContainer, toast, Slide } from "react-toastify";

const initialState = {
  viewState: viewStates.IDLE,
  sims: [],
  totalRecords: 0,
  summary: [],
  filter: {
    startDate: moment().add(-7, 'd'),
    endDate: moment(),
    searchText: "",
    simStatus: "",
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10
  }
};


export function getSims() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    let filter = {
      searchText: state.sims.filter.searchText,
      startDate: toStartOfDay(state.sims.filter.startDate),
      endDate: toEndOfDay(state.sims.filter.endDate),
      currentPage: parseInt(state.sims.filter.currentPage),
      pageSize: parseInt(state.sims.filter.pageSize),
      simStatus: parseInt(state.sims.filter.simStatus)
    };
    let url = buildUrlFromQueryObject("", filter);
    dispatch(setViewState(viewStates.BUSY));
    console.log(`making request to /sims?${process.env.REACT_APP_CLIENT_API}`);
    http_admin
      .get(`/customers/sims?${url}`)
      .then(response => {
        dispatch(setSims(response.data.data.result));
        dispatch(setTotalRecords(response.data.data.totalRecords));
        dispatch(setViewState(viewStates.RETRIEVED));
        // console.table(response.data.data.result)
      })
      .catch(error => {
        ;
      });
  };
}

export function getSimsSummary() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/customers/simsummary`)
      .then(response => {
        dispatch(setSummary(response.data.data));
      })
      .catch(error => {
        ;
      });
  };
}


export function setSummary(summary) {
  return {
    type: "SET_SUMMARY",
    payload: summary
  };
}

export function setStartDate(startDate) {
  return {
    type: "SET_START_DATE",
    payload: startDate
  };
}

export function setEndDate(endDate) {
  return {
    type: "SET_END_DATE",
    payload: endDate
  };
}

export function setCurrentPage(currentPage) {
  return {
    type: "SET_CURRENT_PAGE",
    payload: currentPage
  };
}

export function setSearchText(searchText) {
  return {
    type: "SET_SEARCH_TEXT",
    payload: searchText
  };
}

export function setSimStatus(simStatus) {
  return {
    type: "SET_SIM_STATUS",
    payload: simStatus
  };
}

export function setTotalRecords(totalRecords) {
  return {
    type: "SET_TOTAL_RECORDS",
    payload: totalRecords
  };
}

function setSims(sims) {
  return {
    type: "SET_SIMS",
    payload: sims
  };
}



export default function simsReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        filter: { ...state.filter, currentPage: action.payload }
      };
    case "SET_TOTAL_RECORDS":
      return {
        ...state,
        filter: { ...state.filter, totalRecords: action.payload }
      };
    case "SET_SIM_STATUS":
      return {
        ...state,
        filter: { ...state.filter, simStatus: action.payload }
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        filter: { ...state.filter, searchText: action.payload }
      };
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_SIMS":
      return {
        ...state,
        sims: action.payload
      };
    case "SET_START_DATE":
      return {
        ...state,
        filter: { ...state.filter, startDate: moment(action.payload) }
      };
    case "SET_END_DATE":
      return {
        ...state,
        filter: { ...state.filter, endDate: moment(action.payload) }
      };

    default:
      return state;
  }
}
