import { viewStates, setViewState } from "../utils/constants";
import { getToken, http_admin } from "../utils/axios-module";
import moment from "moment";
import {buildUrlFromQueryObject, toEndOfDay, toStartOfDay} from "../utils/helpers";
import { toast } from "react-toastify";
// import { ToastContainer, toast, Slide } from "react-toastify";

const initialState = {
  viewState: viewStates.IDLE,
  issues: [],
  totalRecords: 0,
  summary: [],
  filter: {
    startDate: moment().add(-7, "d"),
    endDate: moment(),
    searchText: "",
    issueStatus: "",
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10
  }
};

const user = JSON.parse(localStorage.getItem('user'))

export function getIssues() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    const state = getState();
    let filter = {
      searchText: state.issues.filter.searchText,
      startDate: toStartOfDay(state.issues.filter.startDate),
      endDate: toEndOfDay(state.issues.filter.endDate),
      currentPage: parseInt(state.issues.filter.currentPage),
      pageSize: parseInt(state.issues.filter.pageSize),
      status: parseInt(state.issues.filter.issueStatus)
    };
    let url = buildUrlFromQueryObject("", filter);
    dispatch(setViewState(viewStates.BUSY));
    console.log(`making request to /issues?${process.env.REACT_APP_CLIENT_API}`);
    http_admin
      .get(`/CustomerIssues/?${url}`)
      .then(response => {
        dispatch(setIssues(response.data.data.result));
        dispatch(setTotalRecords(response.data.data.totalRecords));
        dispatch(setViewState(viewStates.RETRIEVED));
        console.table(response.data.data.result);
      })
      .catch(error => {});
  };
}

export function getIssuesSummary() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    http_admin
      .get(`/CustomerIssues/summary`)
      .then(response => {
        dispatch(setSummary(response.data.data));
      })
      .catch(error => {});
  };
}

export function assignIssue(payload) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      let body = {
        assignedTo: payload.assignedTo
      };
      http_admin
        .patch(`/CustomerIssues/${payload.issueId}/assign`, body)
        .then(response => {
          dispatch(setViewState(viewStates.RETRIEVED));
          resolve(true);
        })
        .catch(error => {
          resolve(false);
        });
    });
  };
}

export function changeIssueStatus(payload) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setViewState(viewStates.BUSY));
      let body = {
        newStatus: payload.newStatus
      };
      http_admin
        .patch(`/CustomerIssues/${payload.issueId}/status`, body)
        .then(response => {
          dispatch(setViewState(viewStates.RETRIEVED));
          resolve(true);
        })
        .catch(error => {
          resolve(false);
        });
    });
  };
}

export function handleAdminComment({ customerIssueId, adminName, adminId, message }) {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    http_admin
      .post(`/CustomerIssues/${customerIssueId}/comments`, { adminName, adminId, message })
      .then(response => {
        toast.success("Comment added");
      })
      .catch(error => {
        toast.error(error.message)
      });
  };
}

export function setSummary(summary) {
  return {
    type: "SET_SUMMARY",
    payload: summary
  };
}

export function setStartDate(startDate) {
  return {
    type: "SET_START_DATE",
    payload: startDate
  };
}

export function setEndDate(endDate) {
  return {
    type: "SET_END_DATE",
    payload: endDate
  };
}

export function setCurrentPage(currentPage) {
  return {
    type: "SET_CURRENT_PAGE",
    payload: currentPage
  };
}

export function setSearchText(searchText) {
  return {
    type: "SET_SEARCH_TEXT",
    payload: searchText
  };
}

export function setIssueStatus(issueStatus) {
  return {
    type: "SET_ISSUE_STATUS",
    payload: issueStatus
  };
}

export function setTotalRecords(totalRecords) {
  return {
    type: "SET_TOTAL_RECORDS",
    payload: totalRecords
  };
}

function setIssues(issues) {
  return {
    type: "SET_ISSUES",
    payload: issues
  };
}

export default function issuesReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        filter: { ...state.filter, currentPage: action.payload }
      };
    case "SET_TOTAL_RECORDS":
      return {
        ...state,
        filter: { ...state.filter, totalRecords: action.payload }
      };
    case "SET_ISSUE_STATUS":
      return {
        ...state,
        filter: { ...state.filter, issueStatus: action.payload }
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        filter: { ...state.filter, searchText: action.payload }
      };
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_ISSUES":
      return {
        ...state,
        issues: action.payload
      };
    case "SET_START_DATE":
      return {
        ...state,
        filter: { ...state.filter, startDate: moment(action.payload) }
      };
    case "SET_END_DATE":
      return {
        ...state,
        filter: { ...state.filter, endDate: moment(action.payload) }
      };

    default:
      return state;
  }
}
