import React, {useEffect, useState, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ToastContainer, toast, Slide} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Formik, Field, FieldArray, Form, ErrorMessage} from "formik";
import * as Yup from "yup";
import {setLocale} from "yup"

import {getProduct, updateFees} from "../../redux/fees";
import {useSelector, useDispatch} from "react-redux";
import {viewStates} from "../../utils/constants";
import {
  Button,
  Card,
  Col,
  Container,
  FormInput,
  FormSelect,
  Row
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";

const Users = () => {
  const state = useSelector(state => state.fees);
  const dispatch = useDispatch();
  const history = useHistory();
  const param = useParams();


  useEffect(() => {
    dispatch(getProduct(param.id));
  }, []);


  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {
      return <CommissionEntry/>
    }
  };

//   const initialValues = {
//       entries: [
//           {id:0, lowerLimit:0, upperLimit: 0, commissionRate: -1, fee: 0, isActive: false}
//       ]
//   };


  const MyInput = ({field, form, ...props}) => {
    return <FormInput {...field} {...props} />;
  };

// const validationSchema = Yup.object({
//   airtimePurchaseFee: Yup.string().required("This field is required"),
//   airtimeTransferFee: Yup.string().required("This field is required"),
//   airtimeToCashFee: Yup.string().required("This field is required")
// });

  const handleSubmit = (values, resetForm) => {
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();
  };

  const submitFees = (fees) => {
    let product = {productId: parseInt(param.id), commissions: []};
    fees.forEach(fee => {
      product.commissions.push({
        commissionRate: parseInt(fee.commissionRate),
        lowerLimit: parseFloat(fee.lowerLimit),
        upperLimit: parseFloat(fee.upperLimit),
        fee: parseFloat(fee.fee),
        productId: parseInt(fee.productId),
        isActive: fee.isActive
      })
    })

    dispatch(updateFees(product)).then(result => {
      if (result) {
        toast.success(
          "Commissions updated successfully"
        );
        history.push('/fees')

      }
    })

    console.log(product)

  }

  const commissions = state.product.commissions;
  const newEntryObject = {
    id: 0,
    productId: param.id,
    lowerLimit: 0,
    upperLimit: 0,
    commissionRate: "2",
    fee: 0,
    isActive: false
  };


  const validationSchema = Yup.object().shape({
    entries: Yup.array().of(
      Yup.object().shape({
        lowerLimit: Yup.number()
          .required("Specify a lower limit")
          .positive("Upper limit must be positive"),
        upperLimit: Yup.number()
          .required("Specify an upper limit")
          .positive("Upper limit must be positive"),
        fee: Yup.number()
          .required("Fee is required")
          .positive("Fee must be positive"),
        commissionRate: Yup.number()
          .required("Flat or Percentage?")
      })
    )
  });

  const CommissionEntry = () => (
    <Card small className="p-4">
      <Formik
        initialValues={{entries: commissions}}
        validationSchema={validationSchema}
        onSubmit={values => submitFees(values.entries)}
        render={({values, isValid}) => (
          <Form>
            <FieldArray
              name="entries"
              render={arrayHelpers => (
                <div>
                  {values.entries && values.entries.length > 0 ? (
                    values.entries.map((entry, index) => (
                      <Row key={index} className="border-b pb-4 mb-4">
                        <Col className="flex flex-wrap">
                          <div className="mr-2">
                            <label htmlFor="">Range</label>
                            <div className="flex items-center">
                              <div>
                                <Field
                                  name={`entries.${index}.lowerLimit`}
                                  component={MyInput}
                                />
                                <ErrorMessage
                                  name={`entries.${index}.lowerLimit`}
                                >
                                  {msg => (
                                    <span className="text-red-500 text-sm">
                                    {msg}
                                  </span>
                                  )}
                                </ErrorMessage>
                              </div>
                              <div>
                                <Field
                                  name={`entries.${index}.upperLimit`}
                                  component={MyInput}
                                />
                                <ErrorMessage
                                  name={`entries.${index}.upperLimit`}
                                >
                                  {msg => (
                                    <span className="text-red-500 text-sm">
                                    {msg}
                                  </span>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div>
                              <label htmlFor="">Fee</label>
                              <Field
                                name={`entries.${index}.fee`}
                                component={MyInput}
                              />
                              <ErrorMessage name={`entries.${index}.fee`}>
                                {msg => (
                                  <span className="text-red-500 text-sm">
                                {msg}
                              </span>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="ml-2">
                              <label className="block" htmlFor="">
                                Type
                              </label>
                              <Field
                                className="px-2 py-2 rounded-sm border border-gray-300 custom-select-sm"
                                as="select"
                                name={`entries.${index}.commissionRate`}
                              >
                                <option value="2">Flat</option>
                                <option value="1">Percentage</option>
                              </Field>
                              <ErrorMessage
                                name={`entries.${index}.commissionRate`}
                              >
                                {msg => (
                                  <span className="text-red-500 text-sm">
                                {msg}
                              </span>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <div className="ml-2">
                            <label className="block" htmlFor="">
                              Enabled
                            </label>
                            <Field
                              type="checkbox"
                              name={`entries.${index}.isActive`}
                            ></Field>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <button
                            className="mb-2 bg-red-400 w-6 h-6 leading-3 pb-1 rounded-full text-xl text-white block"
                            type="button"
                            onClick={() => {
                              console.log();
                              if (values.entries.length > 1)
                                arrayHelpers.remove(index);
                            }} // remove a friend from the list
                          >
                            -
                          </button>
                          <button
                            className=" bg-blue-primary w-6 h-6 leading-3 pb-1 rounded-full text-xl text-white block"
                            type="button"
                            onClick={() => arrayHelpers.push(newEntryObject)} // insert an empty string at a position
                          >
                            +
                          </button>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <button
                      disabled={!isValid}
                      className="bg-blue-primary px-4 py-2 text-white border-none"
                      type="button"
                      onClick={() => arrayHelpers.push(newEntryObject)}
                    >
                      {/* show this when user has removed all friends from the list */}
                      Add a new commission
                    </button>
                  )}
                  <div>
                    {values.entries && values.entries.length > 0 ? (
                      <Button
                        className="float-right"
                        type="submit"
                      >
                        Update
                      </Button>
                    ) : null}
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      />
    </Card>
  );


  return (
    <>
      <ToastContainer
        autoClose={5000}
        position={toast.POSITION.BOTTOM_CENTER}
        transition={Slide}
        hideProgressBar={true}
      />
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={state.product.name + " Fees"}
                     subtitle="Management"
                     className="text-sm-left"/>
        </Row>
        <Row>
          <Col>
            {renderView()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Users;
