import React, { forwardRef, useImperativeHandle } from 'react'
import { useState } from 'react';
import {FormTextarea} from "shards-react";

const ConfirmationDialog = forwardRef((props, ref) => {

    const[resolve, setResolve] = useState(() => null);
    const[reject, setReject] = useState(() => null);
    const[dialogOpen, setDialogOpen] = useState(false);
    const[title, setTitle] = useState("");
    const[message, setMessage] = useState("");
    const[positiveText, setPositiveText] = useState("");
    const[negativeText, setNegativeText] = useState("");
    const[feedback, setFeedback] = useState("");
    const[requireFeedback, setRequireFeedback] = useState("");

    useImperativeHandle(ref, () => ({
        open(title, message, requireFeedback = false, positiveText = 'Yes', negativeText = 'Cancel'){
          setDialogOpen(true)
          setTitle(title)
          setMessage(message)
          setRequireFeedback(requireFeedback)
          setPositiveText(positiveText)
          setNegativeText(negativeText)
          return new Promise((resolve, reject) => {
            setResolve(() =>resolve)
            setReject(() => reject)
          })
        }
    }))

    const agree = () => {
      if (requireFeedback && !feedback) {
        alert("Please enter your feedback")
        return;
      }
      resolve([true, feedback])
      setDialogOpen(false)
    };

    const cancel = () => {
      resolve([false, feedback])
      setDialogOpen(false)
    };

    const renderInput = () => {
      if (requireFeedback) {
        return (
          <div className="px-4 py-3 sm:px-6 items-center justify-center mt-8">
            <FormTextarea value={feedback} onChange={e => setFeedback(e.target.value)}/>
          </div>
        );
      } else {
        return <React.Fragment></React.Fragment>;
      }
    }

    if(dialogOpen){
        return (
          <div
            v-if="dialog"
            className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10"
          >
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75"></div>
            </div>

            <div className="bg-white rounded overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
              <div className="w-full px-4 py-10">
                <h3 className="text-2xl leading-6 font-bold text-gray-900 text-center">
                  {title}
                </h3>
                <p
                  className="text-base leading-6 text-gray-600 mt-3 text-center"
                >{message}</p>

                {renderInput()}

                <div className="px-4 py-3 sm:px-6 flex items-center justify-center mt-8">
                  <button
                    onClick={cancel}
                    className="border border-blue-primary rounded text-blue-primary w-1/2 py-2 mx-2"
                  >
                    {negativeText}
                  </button>
                  <button
                    onClick={agree}
                    className="text-white bg-blue-primary w-1/2 py-2 rounded mx-2"
                  >
                    {positiveText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
    }

    return null
})

export default ConfirmationDialog;



// export default {
// }
// </script>
// <style scoped>
// .pop-enter-active,
// .pop-leave-active {
//   transition: all 0.2s ease;
//   transform: translateY(10px);
// }

// .pop-enter,
// .pop-leave-to {
//   transform: translateY(-10px);
//   opacity: 0;
// }
// </style>

    // Entering: "ease-out duration-300"
    //   From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //   To: "opacity-100 translate-y-0 sm:scale-100"
    // Leaving: "ease-in duration-200"
    //   From: "opacity-100 translate-y-0 sm:scale-100"
    //   To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
