import React from 'react'
import {useHistory} from 'react-router-dom'
import { useEffect, Fragment } from "react";


const Auth = (props) => {
    const history = useHistory()

    useEffect(() => {
        const user = localStorage.getItem("user");
        if(user === undefined){
            history.push('/login')
        }
    })

    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}

export default Auth
