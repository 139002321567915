import React, { useContext, useState } from "react";
import { FormSelect } from "shards-react";
import { GatewayContext } from "./gatewayContext";

const GatewaySelect = ({ options, selected, getValue, name }) => {
  const [value, setValue] = useState(selected);
  const handleInput = e => {
    setValue(e.target.value);
    getValue(e.target.name, e.target.value);
  };

  return (
    <FormSelect
      name={name}
      onChange={handleInput}
      value={value}
      id="feInputState"
    >
      {options.map(opt =>{
        if(typeof opt === "object") {
          return <option key={opt} value={opt.value} >{opt.name}</option>
        }
        return <option key={opt}>{opt}</option>
      })}
    </FormSelect>
  );
};

export default GatewaySelect;
