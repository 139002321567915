import { getToken, http_admin } from "../utils/axios-module";
import { viewStates, setViewState } from "../utils/constants";
import moment from "moment";
import { toast } from "react-toastify";
import {buildUrlFromQueryObject, toEndOfDay, toStartOfDay} from "../utils/helpers";

const initialState = {
  labels: [],
  datasets: [],
  startDate: moment().add(-7, 'd'),
  endDate: moment(),
  provider: "",
  network: ""
};

export function setProvidersConversionRate(payload) {
  return {
    type: "SET_PROVIDERS_CONVERSION_RATE",
    payload
  };
}

export function setOtpStartDate(payload) {
  return {
    type: "SET_OTP_START_DATE",
    payload
  };
}

export function setOtpEndDate(payload) {
  return {
    type: "SET_OTP_END_DATE",
    payload
  };
}

export function setOtpProvider(payload) {
  return {
    type: "SET_OTP_PROVIDER",
    payload
  };
}

export function setOtpNework(payload) {
  return {
    type: "SET_OTP_NETWORK",
    payload
  };
}

export function handleProvidersConversionRate() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    const { startDate, endDate, provider } = getState().otpProvidersConversionRate;
    let filter = {
      startDate: toStartOfDay(startDate),
      endDate: toEndOfDay(endDate),
      provider: provider
    };
    let params = buildUrlFromQueryObject('', filter);
    http_admin
      .get(`/otps/provider/conversionrate?${params}`)
      .then(data => dispatch(setProvidersConversionRate(data.data.data.conversionModel.data)))
      .catch(error => console.log(error));
  };
}

export function handleProvidersConversionRateByNetwork() {
  http_admin.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  return (dispatch, getState) => {
    dispatch(setViewState(viewStates.BUSY));
    const { startDate, endDate, provider, network} = getState().otpProvidersConversionRate;
    if (!provider) {
      toast.error("Please select a provider")
      return
    }
    http_admin
      .get(`/otps/provider/conversionrate/network?StartDate=${startDate}&EndDate=${endDate}&Provider=${provider}&Network=${network}`)
      .then(data => dispatch(setProvidersConversionRate(data.data.data.conversionModel.data)))
      .catch(error => console.log(error));
  };
}

export function otpProvidersConversionRateReducers(state = initialState, action) {
  switch (action.type) {
    case "SET_PROVIDERS_CONVERSION_RATE":
      return {
        ...state,
        otpProvidersConversionRate: action.payload
      };
    case "SET_OTP_START_DATE":
      return {
        ...state,
        startDate: action.payload
      };
    case "SET_OTP_END_DATE":
      return {
        ...state,
        endDate: action.payload
      };
    case "SET_OTP_PROVIDER":
      return {
        ...state,
        provider: action.payload
      }
    case "SET_OTP_NETWORK":
     return {
      ...state,
      network: action.payload
     }
    default:
      return state;
  }
}
