import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row
} from "shards-react";

const ResponsiveTable = ({
                           headerData,
                           children,
                           title,
                           headerAction
                         }) => {
  return (
    <Card small className="mb-4 overflow-hidden">
      {title || headerAction ? <CardHeader className="border-bottom">
        <Row>
          {title ? (
            <Col>
              <CardTitle className="text-lg">{title}</CardTitle>
            </Col>
          ) : null}
          {headerAction ? (
            <Col>
              <div className="float-right">{headerAction}</div>
            </Col>
          ) : null}
        </Row>
      </CardHeader> : null}
      <CardBody className="p-0 pb-3 overflow-x-scroll">
        <table className="table mb-0">
          <thead className="bg-light">
          <tr>
            {headerData.map((hData, i) => (
              <th
                scope="col"
                key={i}
                className="border-0 text-left"
              >
                {hData}
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {children != null || children.length > 0 ? (
            children
          ) : null}
          </tbody>
        </table>
        {children == null || children.length === 0 ? (
          <p className="text-center text-base p-10 text-lg">No results</p>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default ResponsiveTable;
