import React from "react";
import {lowercaseFirstLetter} from "../../utils/helpers";


const gatewaySatus = (value) => {
  let colour, val;
  if (value === 1) {
    colour = "alert-success";
  } else {
    colour = "alert-danger"
  }
  switch (value) {
    case 1:
      val = "Online";
      break;
    case 2:
      val = "Offline";
      break;
    case 3:
      val = "Error";
      break;
    case 4:
      val = "Disabled";
      break;
    case 5:
      val = "Seeding";
      break;
  }

  return (<span
    className={"px-2 py-1 uppercase text-center rounded-full inline-block font-normal text-sm " + colour}>
     {val}
   </span>);
}

export const tableHeaders = [
  "Name",
  "Priority",
  "Status",
  "Balance",
  "Max Amount",
  "",
];

const BodyData = ({data, handleModal, handleViewSims, priorityType}) => {
  return (
    <>
      {data.map(d => (
        <tr
          key={d.id}
        >
          <td className="w-1/6">
            {d.name}
          </td>
          <td>
            {d[lowercaseFirstLetter(priorityType)]}
          </td>
          <td>
            {gatewaySatus(Number(d.status))}
          </td>
          <td>
            {d.balance}
          </td>
          <td>
            {d.maxAmount}
          </td>
          <td className="w-1/6">
            <span
              className="uppercase text-blue-primary font-normal text-left text-sm px-2 py-3 cursor-pointer"
              onClick={() => handleModal(d, priorityType)}
            >
              Edit
            </span>
            <span
              className="uppercase text-blue-primary font-normal text-left text-sm px-4 py-3 cursor-pointer"
              onClick={() => handleViewSims(d)}
            >
              Sims&rarr;
            </span>
          </td>
        </tr>
      ))}
    </>
  );
};

export default BodyData;
