import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, EmptyLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

import Customers from "./views/customers/Index"
import CustomerProfile from "./views/customers/_id";
import Sims from "./views/sims/Index"
import SimsTransactions from './views/sims/transactions';
import SupportIssues from "./views/SupportIssues";
import InwardMessages from "./views/InwardMessages";
import Otps from "./views/Otps";
import Activities from "./views/Activities";
import Dashboard from './views/Dashboard';
import Users from './views/users/Index';
import LoginPage from './views/Login';
import ForgotPassword from './views/forgot-password/Index';
import PasswordReset from './views/forgot-password/Complete';
import ChangePassword from './views/users/ChangePassword';
import Fees from './views/Fees/Index';
import FeesUpdate from './views/Fees/Update';
import Gateways from "./views/gateways/Gateways";
import Messages from "./views/Messages";
import GatewaySims from "./views/gateways/GatewaySims";
import OtpOverview from "./views/OtpOverview";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/customers",
    layout: DefaultLayout,
    component: Customers
  },
  {
    path: "/customer/:id",
    layout: DefaultLayout,
    component: CustomerProfile
  },
  {
    path: "/sims",
    layout: DefaultLayout,
    component: Sims
  },
  {
    path: "/simstransactions",
    layout: DefaultLayout,
    component: SimsTransactions
  },
  {
    path: "/supportissues",
    layout: DefaultLayout,
    component: SupportIssues
  },
  {
    path: "/inwardmessages",
    layout: DefaultLayout,
    component: InwardMessages
  },
  {
    path: "/otps",
    layout: DefaultLayout,
    component: Otps
  },
  {
    path: "/activities",
    layout: DefaultLayout,
    component: Activities
  },
  {
    path: "/adminusers",
    layout: DefaultLayout,
    component: Users
  },
  {
    path: "/change-password",
    layout: DefaultLayout,
    component: ChangePassword
  },
  {
    path: "/login",
    layout: EmptyLayout,
    component: LoginPage
  },
  {
    path: "/forgot-password",
    layout: EmptyLayout,
    component: ForgotPassword
  },
  {
    path: "/password-reset",
    layout: EmptyLayout,
    component: PasswordReset
  },
  {
    path: "/fees",
    layout: DefaultLayout,
    component: Fees
  },
  {
    path: "/fee/:id",
    layout: DefaultLayout,
    component: FeesUpdate
  },
  {
    path: "/gateways",
    layout: DefaultLayout,
    component: Gateways
  },
  {
    path: "/gateway/:id/sims",
    layout: DefaultLayout,
    component: GatewaySims
  },
  {
    path: "/messages",
    layout: DefaultLayout,
    component: Messages
  },
  {
    path: "/otp-overview",
    layout: DefaultLayout,
    component: OtpOverview
  }
];
