import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Spacer from "../components/custom/Spacer";
import {Card, FormInput} from "shards-react";
import { login } from "../redux/auth";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


const LoginPage = props => {
  const dispatch = useDispatch();
  const history = useHistory()

  const MyInput = ({ field, form, ...props }) => {
    return <FormInput {...field} {...props} />;
  };

  const validationSchema = Yup.object({
    userName: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    )
  });

  const handleSubmit = async (values, resetForm) => {
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();
    dispatch(login(values)).then(response => {
      if (response) {
        resetForm()
        history.push('/dashboard')
      }
    });
  };

  const test = () => {
    props.updateKeys();
  };

  const initialValues = {
    userName: "",
    password: "",
  };

  return (
      <div className="w-full h-screen flex items-center justify-center">
        <Card className="bg-white lg:w-1/4" >
          <h2
            className="text-xl text-center font-semibold px-4 py-4 text-black-500"
            // onClick={test}
          >
              <img
                  id="main-logo"
                  className="d-inline-block align-top mr-1"
                  style={{ maxWidth: "35px" }}
                  src={require("../images/tingtel_logo.png")}
                  alt="Tingtel Dashboard"
              />
              <span className="d-md-inline ml-1">
                  Tingtel Dashboard
              </span>
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, {resetForm}) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({ values, errors, handleSubmit }) => (
              <form className="w-full px-8 py-6" onSubmit={handleSubmit}>
                {/* <p>{JSON.stringify(values)}</p> */}
                <div className="w-full">
                  <label htmlFor="">User name</label>
                  <Field name="userName" component={MyInput} />
                  {errors.userName && (
                    <p className=" text-red-500 text-sm">{errors.userName}</p>
                  )}
                </div>
                <Spacer height="15px" />

                <div className="w-full">
                  <label htmlFor="">Password</label>
                  <Field
                    label="Password"
                    type="password"
                    name="password"
                    component={MyInput}
                  />
                  {errors.password && (
                    <p className="text-red-500 text-sm">{errors.password}</p>
                  )}
                </div>
                <div className="flex items-center justify-start mt-12">
                  <button
                    type="button"
                    className="bg-blue-primary px-4 py-2 text-white border-none w-full"
                    onClick={() => handleSubmit(values)}
                  >
                    Submit
                  </button>
                </div>
                <div className="text-center mt-3">
                  <Link to="/forgot-password" className=" text-blue-primary">
                    Forgot Password?
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      </div>
  );
};

export default LoginPage;
