import React, {useEffect, useState, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ToastContainer, toast, Slide} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  toThousandCurrencyFormat,
  toThousandFormat
} from "../../utils/helpers";

import {
  getProducts
} from "../../redux/fees";

import {useSelector, useDispatch} from "react-redux";
import {viewStates} from '../../utils/constants';
import {Col, Container, Row} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import ResponsiveTable from "../../components/custom/ResponsiveTable";

const Products = () => {
  const state = useSelector(state => state.fees);
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getProducts());
    console.log(state);

  }, []);

  const renderView = () => {
    if (state.viewState === viewStates.ERROR) {
      return <p>Error occurred</p>;
    } else if (state.viewState === viewStates.RETRIEVED) {

      return <ProductsListView/>;
    }
  };


  const ProductsListView = () => {
    const products = state.products;

    const commissionTypes = {
      1: <p>Percentage</p>,
      2: <p>Flat</p>
    }

    const renderTable = (commissions, name, id) => {
      if (commissions.length === 0) {
        return <p>There are no commissions setup for this transaction type</p>
      } else {
        return (
          <ResponsiveTable
            title={name}
            headerData={["Bucket", "Fee", "Type", "Status"]}
            headerAction={<button
              className="bg-blue-primary px-4 py-2 text-white border-none"
              onClick={() => history.push(`/fee/${id}/update`)}
            >
              Edit
            </button>}
          >
            {commissions.map((commission, index) => {
              return (
                <tr
                  key={commission.id}
                >
                  <td>
                    NGN {toThousandCurrencyFormat(commission.lowerLimit)} -
                    NGN {toThousandCurrencyFormat(commission.upperLimit)}
                  </td>
                  <td>
                    {commission.fee}
                  </td>
                  <td>
                    {commissionTypes[commission.commissionRate]}
                  </td>
                  <td>
                    {commission.isActive ? <p>Active</p> : <p>Inactive</p>}
                  </td>
                </tr>
              );
            })}
          </ResponsiveTable>

        )
      }
    }

    return (
      <React.Fragment>
        {products.map(product => {
          return (
            renderTable(product.commissions, product.name, product.id)
          );
        })}
        {/*  */}
      </React.Fragment>
    );
  };


  return (
    <>
      <ToastContainer
        autoClose={5000}
        position={toast.POSITION.BOTTOM_CENTER}
        transition={Slide}
        hideProgressBar={true}
      />
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Fees" subtitle="Management"
                     className="text-sm-left"/>
        </Row>
        <Row>
          <Col>
            {renderView()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Products;
